import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./CarsStyle";
import Cars from "../../components/cars";
const CarsPage = React.memo((props) => {
  return (
    <>
      <Cars />
    </>
  );
});

export default withRouter(CarsPage);
