import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Contact from "components/home/contact";
import {
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
    Paper,
    Box
} from '@mui/material';
import GetInTouch from "components/GetInTouch";

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  stepIcon: {
    '&.Mui-active': {
      color: '#fff', // Custom active color
    }
  },
}));

export default function WhoWeAre(props) {
    const classes = useStyles();

    const steps = [
        { label: '2024', description: "recognized as a leader in exotic car rentals, we expanded our reach to top us cities and now offer 13+ exotic cars, continuing to create unforgettable moments for our clients." },
        { label: '2021', description: "expanded our fleet and personalized rental packages, building a loyal client base through exceptional service and unforgettable experiences, making dreams of driving exotic cars a reality." },
        { label: '2018', description: "founded with a passion for luxury and excitement, monaco experiences began offering exclusive supercar rentals, aiming to turn every drive into a memorable adventure for our clients." }
    ];

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Who we are | Wedding Exotic Car Rental Specialist in US  
                </title>
                <meta name="description" content="Monaco Experiences is the most trusted provider of exotic and luxury car rentals for weddings, events in NJ,NYC,La & across the United States, offering a seamless and unforgettable experience in the car of your dreams" />
                <meta name="keywords" content="exotic car rental nyc, luxury car rental nyc, exotic car rental nj, luxury car rental nj, exotic car rental la, luxury car rental la" />
                <link rel="canonical" href="https://monacoexperiences.com/who-we-are" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/who-we-are-main.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '600px',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>who we are</h1>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>

                </div>



                <div class="pt-30 pb-30">
                    <div class="container">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-40 fw-600 text-center" style={{ color: '#fff' }}>
                                    we are a top exotic car rental service provider in the us.
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12 mb-30">
                            <p class="font-montserrat fw-300 fs-20" style={{ color: '#fff' }}>
                                monaco experiences stands as the premier exotic and luxury car rental service provider in the united states, committed to <span className="fw-700">making your dreams a reality</span>. our unwavering dedication to <span className="fw-700">client satisfaction</span> sets us apart in the industry. we prioritize our clients above all, ensuring each interaction is characterized by exceptional service.
                            </p>
                            <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                                our mission is to offer more than just a rental service; we aim to create unforgettable experiences. from the moment you reach out to us, our <span className="fw-700">professional</span> and <span className="fw-700">friendly team</span> is dedicated to understanding and fulfilling your unique needs. we strive to make the process seamless, so you can focus on the thrill of driving an <span className="fw-700">exotic car</span>.
                            </p>
                            <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                                what truly makes us the best is our relentless <span className="fw-700">pursuit of excellence</span>. we believe that everyone deserves the chance to experience the exhilaration of driving a luxury vehicle. by providing access to the most coveted cars in the world, we make this <span className="fw-700">dream accessible to all</span>. at monaco experiences, your satisfaction and happiness are our <span className="fw-700">top priorities</span>, and we are here to ensure that <span className="fw-700">every drive is a memorable adventure.</span>
                            </p>
                        </div>

                        <div style={{ background: '#3E3E3E', color: '#fff', padding: '20px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between' }}>
                            <div class="font-montserrat">
                                <b>1000+</b>&nbsp;
                                happy customers
                            </div>
                            <div class="font-montserrat">
                                <b>
                                    12+
                                </b>&nbsp;
                                exotic cars
                            </div>
                            <div class="font-montserrat">
                                <b>
                                    100%
                                </b>&nbsp;
                                positive feedbacks
                            </div>
                            <div class="font-montserrat">
                                <b>
                                    6+
                                </b>&nbsp;
                                years expertise
                            </div>
                        </div>

                        <div class="mt-30 mb-50 container">
                            <div class="row">
                                <div class="col-lg-6 mt-50" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <img src="../../../../monaco_assets/mission.png" style={{ height: '50px' }} />
                                    <h2 class="text-center font-montserrat fs-20 fw-700 cl-white mt-10">our mission</h2>
                                    <p class="text-center font-montserrat fs-20 fw-300 mt-10" style={{ color: '#E7E7E7' }}>
                                        to <span className="fw-700">make dreams come true</span> by providing our clients with the thrill of driving a supercar. we believe everyone <span className="fw-700">deserves</span> to live their dream of driving an exotic car and creating <span className="fw-700">unforgettable memories</span>.
                                    </p>
                                </div>

                                <div class="col-lg-6 mt-50" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <img src="../../../../monaco_assets/vision.png" style={{ height: '50px' }} />
                                    <h2 class="text-center font-montserrat fs-20 fw-700 cl-white mt-10">our vision</h2>
                                    <p class="text-center font-montserrat fs-20 fw-300 mt-10" style={{ color: '#E7E7E7' }}>
                                        to be known for our passion for <span className="fw-700">excellence</span> and <span className="fw-700">client satisfaction</span>. we aim to provide everyone with the opportunity to <span className="fw-700">live their dream</span> of driving a super exotic car.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-30 mb-50 container">
                    <div class="text-center cl-white font-montserrat mb-50 fs-25 fw-700">our journey so far</div>

                    <Stepper activeStep={-1} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={index} active
                                sx={{
                                    "& .MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                                        color: "#fff",
                                    }
                                }}
                            >
                                <StepLabel StepIconProps={{ classes: { root: classes.stepIcon } }}>
                                    <Typography className="font-montserrat" style={{ color: '#E7E7E7', fontSize: '24px', fontWeight: '700' }}>{step.label}</Typography>
                                </StepLabel>
                                <StepContent>
                                    <Typography className="font-montserrat" style={{ color: '#E7E7E7', fontSize: '20px', fontWeight: '300' }}>{step.description}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>

                <div class="container pt-30">
                    <GetInTouch />
                </div>

            </div>
        </div>

    )
}
