export const localRoute = {
  home: '/',
  about: '/about-us',
  gallery: '/gallery',
  reviews: '/reviews',
  mansions: '/house-of-monaco',
  blog: '/blogs',
  blogDetail: '/blog',
  exoticFleet: '/exotic-fleet',
  whoWeAre: '/who-we-are',

  cars: '/ferrari-f8-rental',
  audiRental: '/audi-r8-rental',
  bentleyBentaygaRental: '/bentley-bentayga-rental',
  bentleyGTCRental: '/bentley-gtc-rental',
  chevroletRental: '/chevrolet-corvette-stingray-rental',
  bmwCompetitionRental: '/bmw-competition-rental',
  lamborghiniHuracanRental: '/lamborghini-huracan-rental',
  lamborghiniHuracanEvoRental: '/lamborghini-huracan-evo-rental',
  excaliburPheaton1972Rental: '/excalibur-pheaton-1972-rental',
  farrari458Rental: '/farrari-458-rental',
  mclaren720sRental: '/mclaren-720s-rental',
  porscheSpeedster1956Rental: '/porsche-speedster-1956-rental',
  rollsRoyceDawnRental: '/rolls-royce-dawn-rental',
  
  astonMartinRental: '/aston-martin-db11-rental',
  rollsRoyceWraithRental: '/rolls-royce-wraith-rental',
  
  location: '/exotic-car-rental-boston',
  LosAngeles: '/exotic-car-rental-los-angeles',
  cancun: '/exotic-car-rental-cancun',
  philadelphia: '/exotic-car-rental-philadelphia',
  newYork: '/exotic-car-rental-new-york',
  newJersey: '/exotic-car-rental-new-jersey',
  puerto: '/exotic-car-rental-puerto-rico',
  washington: '/exotic-car-rental-washington-dc',
  medellin: '/exotic-car-rental-medellin',

  services: '/mobile-baraat-experiences',
  exoticCarRentalForWedding:'/exotic-car-rental-for-wedding',
  exoticCarRentalForMusicVideo:'/exotic-car-rental-for-music-video',
  exoticCarRentalForPhotoshoot:'/exotic-car-rental-for-photoshoot',
  selfDrivenExoticCarRental:'/self-driven-exotic-car-rental',
  vintageCarRental:'/vintage-car-rental',

  notFound: '*',
  notFound500: '*',
}
