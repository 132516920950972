import React, { useEffect, useState } from "react";
import { localRoute } from '../../../routes/localRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export default function ExoticCarBlog() {
    const history = useHistory()
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])

    const handleBackBlogs = () => {
        history.push(localRoute.blog)
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://api.monacoexperiences.com/api/v1/articles/category/blog/?limit=10&is_featured=True");
                if (!response.ok) {
                    throw new Error("Failed to fetch blogs");
                }
                const data = await response.json();
                setBlogs(data.results);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const handleOpenBlog = (blog_slug) => {
        history.push(`${localRoute.blogDetail}/${blog_slug}`);
        console.log("blog_slug 11", blog_slug)
    };

    return (
        <div class="container">
            <div class="font-montserrat pt-30 pb-30" data-aos="fade-up" data-aos-duration="1200">

                <div class="section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600 mb-10" style={{ color: '#ffffff' }}>
                        exotic car rental blogs & insights
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        learn and explore with our exotic car rental blogs. get informative tips and insider knowledge to elevate your experience
                    </p>
                </div>

                <div class="row">

                    {blogs.map(blog => (
                        <div className="col-lg-4 mt-30" key={blog.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div onClick={() => handleOpenBlog(blog.slug)} style={{ cursor: 'pointer' }}>
                                <img src={blog.cover} alt="Blog Thumbnail" />

                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <span class="font-montserrat fs-10 mt-10" style={{ color: '#fff' }}>{moment(blog.updated_at).format('ll')}</span>
                                    <span class="font-montserrat fs-20 fw-600" style={{ marginBottom: '5px', color: '#fff' }}>{blog.title}</span>
                                    <a style={{ display: 'flex', cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '3px', padding: '3px 10px 5px' }} >
                                        <span style={{ margin: 'auto', fontSize: '16px', color: '#000' }} onClick={() => handleOpenBlog(blog.slug)}>read more</span>
                                    </a>
                                </div>


                            </div>
                        </div>
                    ))}
                </div>

                <div class="text-center mt-30">
                    <h2 class="font-montserrat fs-24 fw-600" style={{ color: '#B88B2B' }}>
                        <a onClick={handleBackBlogs} style={{ cursor: 'pointer', color: '#fff' }}>
                            &lt; read blogs and insights &gt;
                        </a>
                    </h2>
                </div>

            </div>
        </div>
    )
}
