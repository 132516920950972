import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car/ExoticRental16Car";


export default function SelfDrivenExoticCarRental() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what are the requirements to drive an exotic car?",
            answer: "you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what types of luxury car rentals available ?",
            answer: "featuring everything from timeless vintage classics to the latest exotic models. explore our full collection here.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "can i extend my rental period ?",
            answer: "yes, you can extend your rental period, subject to vehicle  availability and additional charges.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Self Driven Cars on renta in NJ,NY,LA | Monaco Experiences
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/self-driven-exotic-car-rental" />
            </Helmet>

            <div class="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/self-driven-exotic-car-rental.svg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '85vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>self driven exotic car rental</h1>
                        <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                    </div>
                </div>

            </div>


            <div class="container mt-30">

                <div class="row" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mb-10">
                        <h2 class="font-montserrat fs-30 fw-600 mb-20" style={{ color: '#fff', textAlign: 'center', lineHeight: '1' }}>
                            command the road: self-drive exotic car escape
                        </h2>
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../../monaco_assets/self-driven-car-1.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>
                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            <span class="fw-700">imagine</span> the roar of a lamborghini huracan, the sleek curves of a ferrari f8 spider, or the <span class="fw-700">timeless luxury</span> of a rolls royce dawn, all under your control. at monaco experiences, we bring your <span class="fw-700">driving fantasies</span> to life with our self-drive exotic car rentals. feel the exhilaration as you <span class="fw-700">take the wheel</span> and cruise through breathtaking destinations like la, new york, or cancun.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our self-driven luxury car rentals are perfect for weddings, photoshoots, or simply indulging in an <span class="fw-700">unforgettable drive</span>. experience the highest level of automotive performance and style with our fleet of <span class="fw-700">world-renowned</span> vehicles. with our self-driven exotic car rentals, you dictate the pace of your adventure, enjoying unparalleled <span class="fw-700">freedom on the open road</span>.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            for those with a passion <span class="fw-700">for classic elegance</span>, we also offer self-driven vintage car rentals, letting you relive the golden age of motoring. our vintage collection adds a touch of timeless charm to your journey, perfect for making <span class="fw-700">lasting memories.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            embrace the ultimate driving experience today. book your exotic car escape with monaco experiences and turn every journey into a <span class="fw-700">grand adventure.</span>
                        </p>
                    </div>
                    <div class="col-lg-12 mt-20">
                        <img src="../../../../monaco_assets/self-driven-car-2.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff', textAlign: 'center' }}>
                            get into the cockpit: the luxury of exotic cars
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            ever wondered what it's like to get into the cockpit of an exotic car? imagine you slide into the driver's seat, <span class="fw-700">surrounded by luxury</span>, where every detail is crafted to perfection. the leather seats embrace you, the advanced controls are at your fingertips, and the <span class="fw-700">roar of the engine</span> sends shivers down your spine.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            driving an exotic car isn't just about transportation, <span class="fw-700">it's an experience!</span> whether you're renting for a wedding, a photoshoot, or a music video, or just for the thrill of self-driving, the unmatched luxury and performance elevate every moment. our self driven luxury car rental options offer you the <span class="fw-700">freedom to enjoy</span> the ride on <span class="fw-700">your own terms</span>. from self driven exotic car rental to self driven vintage car rental, each ride promises an unforgettable journey.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            ready to turn heads and feel the excitement? exotic car rental from monaco experiences is <span class="fw-700">your ticket</span> to a world where <span class="fw-700">luxury meets adrenaline.</span> don't just drive - experience the ultimate in car hire services today!
                        </p>
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/self-driven-car-3.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/self-driven-car-4.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/self-driven-car-5.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rental
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>


            <DriveTheDream />

            <Contact />

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
