import React from 'react'
import { useEffect, useState, useContext } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Find_Taskers_Common from 'components/Find_Taskers_Common';



export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <div id='enquiry-form1'>
                <div class="font-montserrat container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12">
                            <div class="consultation-form-area">
                                <Find_Taskers_Common subscriber_type="Contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
