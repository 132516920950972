import React, { useEffect, useState, useContext, } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function LamborghiniHuracanEvo() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/lamborghini-huracan-evo-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/lamborghini-huracan-evo-2.png",
        "../../../../monaco_assets/lamborghini-huracan-evo-3.png",
        "../../../../monaco_assets/lamborghini-huracan-evo-4.png",
        "../../../../monaco_assets/lamborghini-huracan-evo-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a lamborghini huracan evo?",
            answer: "the rental cost varies based on the duration and specifics of your rental agreement. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a lamborghini huracan evo?",
            answer: "to rent a lamborghini huracan evo, you must be at least 25 years old, possess a valid driver’s license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 2,
            question: "are there any mileage restrictions when hiring the lamborghini huracan evo?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "can the lamborghini huracan evo be delivered to my location?",
            answer: "yes, we offer a delivery and pick-up service for the lamborghini huracan to your specified address within our service areas for an additional fee.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "what happens if the lamborghini huracan evo is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "where can i find lamborghini rental near me?",
            answer: "monaco experiences provides lamborghini rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Lamborghini rental by Monaco Experiences Exotic Car Rental NJ NYC
                </title>
                <meta name="description" content="Lamborghini rental near me by Monaco Experiences for the best luxury experience. Contact us for a customized quotation as per your needs" />
                <meta name="keywords" content="lamborghini for rent, lambo rental, rent a lamborghini, rent lamborghini, lamborghini huracan for rent, lamborghini rental, lamborghini rentals" />
                <link rel="canonical" href="https://monacoexperiences.com/lamborghini-huracan-evo-rental" />
            </Helmet>
            
            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo4.png" alt="Image" style={{ height: '35px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '3px' }}>
                            <p>
                                <span class="font-montserrat fs-16 fw-700" style={{ color: '#fff' }}>LAMBORGHINI HURACAN-EVO</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                5.2l v10  |  640 horsepower  |  actual msrp : $ 267,569
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 3px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            lamborghini rental is a dream come true for many americans
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            for many car enthusiasts, a lamborghini is the ultimate <span class="fw-700">dream come true</span>. at monaco experiences, we make this dream a reality with our premium lambo rental services. imagine the thrill and prestige of driving one of the most <span class="fw-700">iconic supercars</span> in the world. whether you want to rent a lamborghini for a day or for a special event, we offer flexible options to suit your needs.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            our lamborghini rentals offer an unparalleled combination of <span c lass="fw-700">luxury, performance</span>, and <span class="fw-700">style</span>. in fact, over <span class="fw-700">95%</span> of our customers rate their experience as "exceptional." you not only get to drive an iconic supercar but also enjoy an unforgettable experience. our <span class="fw-700">seamless rental process</span> ensures that you get behind the wheel of <span class="fw-700">your dream car</span> with ease.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            whether you're planning a special event or just want to treat yourself to a <span class="fw-700">day of luxury</span>, choose monaco experiences. with our top-notch lamborghini rentals, you're <span class="fw-700">guaranteed</span> an extraordinary experience that you'll <span class="fw-700">remember for a lifetime.</span>
                        </p>

                        <p class="font-montserrat fw-700 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            contact us now to reserve your lamborghini and make your next event unforgettable! 🥳
                        </p>
                    </div>
                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury car rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
