import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./GalleryStyle";
import Gallery from "../../components/gallery";
const GalleryPage = React.memo((props) => {
  return (
    <>
      <Gallery />
    </>
  );
});

export default withRouter(GalleryPage);
