import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car/ExoticRental16Car";

export default function VintageCarRental() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a vintage car?",
            answer: "at monaco experiences, vintage car rental prices vary based on the model and rental duration. contact us for specific pricing details.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a vintage cars?",
            answer: "to rent a vintage car, you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "are there any mileage restrictions when hiring the porsche speedster 1956 ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can the porsche speedster 1956 be delivered to my location?",
            answer: "yes, we offer a delivery and pick-up service for all the cars to your specified address within our service areas for an additional fee.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the porsche speedster is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "can i rent a vintage car for wedding?",
            answer: "yes, you can rent a vintage car for your wedding with monaco experiences. our stunning vintage cars add timeless elegance and sophistication to your special day. also, check out our excalibur.",
            isCollapsed: true,
        },
        {
            id: 7,
            question: "where can i find the vintage car rental near me?",
            answer: "monaco experiences provides vintage car rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Vintage car Rental for Wedding by Monaco Experiences
                </title>
                <meta name="description" content="Make your wedding unforgettable with our exotic car rentals. Choose from luxury brands like Ferrari, Lamborghini & Rolls Royce to arrive in style. Get a quote today" />
                <meta name="keywords" content="Vintage car hire for wedding, classic car hire" />
                <link rel="canonical" href="https://monacoexperiences.com/vintage-car-rental" />
            </Helmet>

            <div class="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/vintage-car-rental.svg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '85vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>vintage car rental</h1>
                        <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                    </div>
                </div>

            </div>


            <div class="container mt-30">

                <div class="row" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mb-10">
                        <h2 class="font-montserrat fs-30 fw-600 mb-20" style={{ color: '#fff', textAlign: 'center', lineHeight: '1' }}>
                            the ultimate throwback: rent a vintage car and drive in style
                        </h2>
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../../monaco_assets/vintage-1.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            step back in time with monaco experiences and <span class="fw-700">relish</span> the charm of yesteryear with our exclusive vintage car rentals. whether you're a classic <span class="fw-700">car enthusiast</span> or planning an event with a <span class="fw-700">touch of elegance</span>, our collection is tailored to make your dreams come true. imagine cruising in a vintage porsche speedster 1956, feeling the <span class="fw-700">blend of timeless</span> design and modern innovation with its tesla-powered electric engine.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our vintage & classic car rental offers a unique and <span class="fw-700">sophisticated touch</span>, ensuring your big day is memorable and attractive. choose from our exquisite lineup, including the <span class="fw-700">iconic</span> porsche speedster 1956 or excalibur 1972, and make a grand entrance that echoes <span class="fw-700">style and luxury.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            monaco experiences specializes in providing old vintage cars that not only enhance your event but also <span class="fw-700">create lasting memories</span>. our commitment to quality and customer satisfaction ensures you have a seamless experience from start to finish. dive into nostalgia and make your <span class="fw-700">special moments</span> unforgettable with our classic car rentals. discover the perfect blend of <span class="fw-700">history</span> and <span class="fw-700">luxury</span> with monaco experiences, your premier destination for vintage car rentals.
                        </p>
                    </div>

                    <div class="col-lg-12 mt-20">
                        <img src="../../../../monaco_assets/vintage-2.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff', textAlign: 'center' }}>
                            step back in time : discover the charm of vintage car rental
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            imagine yourself behind the wheel of an exquisite antique car, feeling the simplicity of vintage design combined with the thrill of classic performance. there's something magical about driving a classic car – the way the engine purrs, the tactile feel of the steering wheel, and the admiring glances from passersby. our collection includes the iconic porsche speedster, a timeless beauty featured in the hit song "calm down." this classic car rental offers more than just a ride; it’s an opportunity to relive history with every mile.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            whether you're looking to make a grand entrance at a wedding, add a touch of nostalgia to a photoshoot, or simply enjoy a leisurely drive, our vintage car rentals provide an unforgettable experience
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            feel the allure of classic car rental and make your journey as memorable as your destination.
                        </p>
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/vintage-3.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/vintage-4.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/vintage-5.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rental
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>


            <DriveTheDream />

            <Contact />

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>

        </>
    )
}
