import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";

export default function Newyork(props) {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of luxury cars are available for rent in new york?",
            answer: "we offer a wide range of luxury cars, including lamborghini huracan, ferrari f8 spyder, rolls royce dawn, bentley gtc, mclaren and more to check our full exotic cars fleet click here",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why moncao experiences is the best exotic car rental services in new york?",
            answer: "at monaco experiences, we offer top-notch exotic car rental services in new york, featuring luxury brands like lamborghini, ferrari, rolls royce, and more. our exceptional customer service and premium car selection makes the best choice for your exotic car rental needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "do you provide baraat packages in new york?",
            answer: "yes, we provide specialized baraat packages in new york. our unique baraat experience mobile van is equipped with a tv, smoke gun, dj, lights, and a beer/wine tank, perfect for indian weddings. contact us to customize your baraat package.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i get a luxury car rental for a wedding in new york?",
            answer: "yes, we offer luxury car rentals for weddings in new york to make your special day extraordinary. choose from our wide selection of luxury cars to add a touch of elegance to your wedding. check our full exotic cars fleet here",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Luxury Car Rental New York by Monaco Experiences 
                </title>
                <meta name="description" content=" Experience with our exotic and luxury car rental in New York. Choose from Ferrari, Lamborghini & Rolls Royce, Mclaren, Porsche and many more for weddings & events rental. call +1.862.365.9250 now!" />
                <meta name="keywords" content="sports car rental ny, sports car rental new york, luxury car hire ny, luxury car hire new york" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-new-york" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/new-york.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental new york</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>
                </div>

                <div style={{ background: '#111' }}>
                    <div class="container pt-30 pb-30">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-30 fw-800 text-center" style={{ color: '#fff' }}>
                                    live the high life: exotic car rentals in nyc by monaco experiences
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                in recent years, new yorkers have embraced <span className="fw-700">a shift towards luxury</span> and exclusivity, especially when it comes to grand occasions. the trend of opting for exotic car rentals has surged, reflecting a desire for <span className="fw-700">unique and memorable</span> experiences. whether it's for weddings, milestone celebrations, corporate events, or high-profile parties, new yorkers are choosing to <span className="fw-700">arrive in style</span>, making a statement with super cars like lamborghinis, ferraris, and rolls royces.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                this change is driven by a <span className="fw-700">growing appreciation</span> for the finer things in life and the need to stand out in the bustling, vibrant city. exotic car rentals from monaco experiences provide the perfect <span className="fw-700">blend of luxury</span>, performance, and prestige, allowing individuals to elevate their <span className="fw-700">special moments</span>. additionally, the convenience and flexibility offered by rental services <span className="fw-700">make it easier</span> than ever to access these high-end vehicles <span className="fw-700">without the long-term commitment of ownership</span>.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                monaco experiences caters to this evolving demand by offering a fleet of exotic cars, ensuring that every <span className="fw-700">event becomes a</span> grand spectacle. as new yorkers continue to seek <span className="fw-700">extraordinary experiences</span>, monaco experiences has become the go-to choice for making any occasion truly unforgettable.
                            </p>
                        </div>
                    </div>
                </div>

                <LogoScrolling />

                <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                    <div class="container section-heading text-center">
                        <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                            available exotic cars in new york
                        </h2>
                        <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                            classy and luxury rental cars in <span style={{ fontWeight: 700 }}>new york</span> : discover our unique <span style={{ fontWeight: 700 }}>showroom experience.</span>
                        </p>
                    </div>
                    <ExoticRental16Car extraText={"RENTAL IN NEW YORK"} />
                </div>


                <DriveTheDream extraText={"in new york"} />

                <StoriesFromCustomer />
                <div style={{ background: '#111' }}>
                    <div style={{ background: '#111' }}>
                        <div className="container pt-30 pb-30">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                    frequently asked questions
                                </h2>
                                <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                    these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                                </p>
                            </div>

                            {faqItems.map((item) => (
                                <div key={item.id} className="container mt-40">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3
                                            className="font-montserrat fs-20 fw-500"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer' }}
                                        >
                                            {item.question}
                                        </h3>
                                        <h3
                                            className="font-montserrat"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                        >
                                            {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                        </h3>
                                    </div>
                                    <hr style={{ borderColor: '#BABABA' }} />
                                    {!item.isCollapsed && (
                                        <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
