import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car/ExoticRental16Car";


export default function RentalCarsServices() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what is included in the mobile baraat rentals package?",
            answer: "our mobile baraat rentals package includes a luxurious van equipped with a premium sound system, dynamic lighting, a dj setup, a confetti cannon, a smoke gun, and a beer and wine tank. we provide everything you need to create an unforgettable baraat experience.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "how do i book a mobile baraat how do i make a booking with monaco experiences? with monaco experiences?",
            answer: "booking a mobile baraat rental with monaco experiences is simple. just contact us through our website or give us a call. we will guide you through the booking process and customize the package to meet your specific needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "where can i find mobile baraat rental near me?",
            answer: "monaco experiences provides mobile baraat rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Mobile Baraat DJ in NJ,NY,LA | Monaco Experiences
                </title>
                <meta name="description" content=" The one and only Mobile Baraat DJ in the US with Monaco Experiences, Enjoy live music, lights, TV, smoke gun, and a beer/wine tank for an unforgettable wedding celebration on wheels. Serving NJ, NY, and LA. " />
                <meta name="keywords" content="mobile baraat, baraat van, baraat smoke gun, dj baraat" />
                <link rel="canonical" href="https://monacoexperiences.com/mobile-baraat-experiences" />
            </Helmet>

            <div class="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/mobile-baraat.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '85vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>mobile baraat experiences</h1>
                        <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                    </div>
                </div>

            </div>


            <div class="container mt-30">

                <div class="row" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mb-10">
                        <h2 class="font-montserrat fs-30 fw-600 mb-20" style={{ color: '#fff', textAlign: 'center', lineHeight: '1' }}>
                            party on wheels : ab raja ki aayegi exotic baraat !
                        </h2>
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../../monaco_assets/mobile-baraat-experiences-1.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>
                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            experience the grandeur of a <span class="fw-700">traditional baraat</span> with a modern twist through monaco experiences, our mobile baraat package, featuring a luxurious mercedes van, is designed to bring unparalleled excitement and joy to your special day, making it a <span class="fw-700">celebration to remember.</span>
                        </p>

                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            monaco experiences specializes in creating unforgettable mobile baraat experiences that cater to your unique needs. our state-of-the-art <span class="fw-700">mercedes van</span> is equipped with a premium sound system, vibrant lights, <span class="fw-700">a smoke gun</span>, and a <span class="fw-700">dj setup</span> to keep the energy high throughout the journey. the van also includes a <span class="fw-700">beer and wine tank</span> to ensure your guests are entertained and refreshed.
                        </p>

                        <p class="font-montserrat fw-300 fs-20 mt-20" style={{ color: '#fff' }}>
                            designed with a deep appreciation for indian traditions, our mobile baraat is perfect for grooms looking to make a <span class="fw-700">spectacular entrance</span>. it's not just a ride, it's an experience that <span class="fw-700">elevates</span> the traditional baraat to new heights. let monaco experiences help you create a baraat that your guests will talk about for years to come. choose us for a celebration like no other.
                        </p>
                    </div>
                    <div class="col-lg-12 mt-20">

                        <img src="../../../../monaco_assets/mobile-baraat-experiences-2.png" alt="Image" style={{ borderRadius: '5px' }} />

                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff', textAlign: 'center' }}>
                            extraordinary mobile baraat - only with monaco experiences
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            imagine a pre-wedding party with your personal <span class="fw-700">dj in the house</span>, playing your favorite indian baraat songs “kala chashma” and top bollywood hits, setting the <span class="fw-700">perfect mood for the celebration</span>.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our luxurious van is fitted with <span class="fw-700">top-notch sound systems</span>, dynamic lighting, a dj setup, and a <span class="fw-700">confetti cannon</span> to create a festive atmosphere that keeps <span class="fw-700">everyone on their feet.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            with monaco experiences, your baraat will be a unique blend of <span class="fw-700">excitement and tradition</span>. the onboard entertainment, including a smoke gun and a beer and wine tank, guarantees a lively and enjoyable journey for all. by choosing us, you ensure a standout entrance that leaves a lasting impression. make your wedding day unforgettable with monaco experiences <span class="fw-700">extraordinary mobile baraat</span>.
                        </p>
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/mobile-baraat-experiences-3.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/mobile-baraat-experiences-4.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/mobile-baraat-experiences-5.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rental
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>


            <DriveTheDream />

            <Contact />

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>

        </>
    )
}
