import React from 'react'
import { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import Video from '../../assets/video/video.mp4';


export default function About() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        // speed: 1000,
        autoplaySpeed: 1500,

        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    About us | Moncao Experiences | Since 2018
                </title>
                <meta name="description" content="We are leading wedding baraat car rental service since 2018, Monaco Experiences offers a stunning fleet of luxury and exotic cars. Make your wedding unforgettable with our top service and stylish vehicles." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/who-we-are/" />
            </Helmet>

            <div class="font-montserrat mt-150 md-mt-150">
                <div class="container">
                    <div data-aos="fade-up" class="title-style-two text-center mb-35 md-mb-10">
                        <div class="row">
                            <div class="col-lg-10 m-auto">
                                <h1 class="font-montserrat fw-600" style={{ color: '#B88B2B', fontSize: '24px' }}>your gateway to a unforgettable experience</h1>
                                <div class="col-12 order-first mt-50">
                                    <div class="portfolio-thumb">
                                        {/* <img style={{ rotate: "25%" }} src="assets/about/groupimg.jpg" alt="monaco group image" /> */}
                                        {/* <img src={Video} alt="India’s Best Job Search App Video" /> */}
                                        <video loop autoPlay>
                                            <source src={Video} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h1 data-aos="fade-up" data-aos-duration="1200" class='col-lg-12 fs-24 font-montserrat'>
                            why monaco experiences?
                        </h1>
                        <p class='font-montserrat mt-20 col-lg-12' style={{ textAlign: "left", color: '#fff', fontSize: '14px' }} data-aos="fade-up" data-aos-duration="1200" >
                            WELCOME TO OUR WORLD OF LUXURY AND HIGH-PERFORMANCE AUTOMOBILES. WE ARE A PREMIER SUPERCAR RENTAL COMPANY BASED IN NEW JERSEY, OWNED AND OPERATED BY MR. VIRAL CHOKSHI AND TEAM, A CAR ENTHUSIAST WITH A PASSION FOR EXOTIC VEHICLES. AT OUR COMPANY, WE BELIEVE THAT DRIVING A SUPERCAR IS NOT JUST ABOUT TRANSPORTATION, BUT AN EXPERIENCE THAT SHOULD BE CHERISHED AND REMEMBERED FOREVER. THAT’S WHY WE STRIVE TO PROVIDE OUR CLIENTS WITH AN UNMATCHED LEVEL OF SERVICE, OFFERING A WIDE RANGE OF LUXURY AND HIGH-PERFORMANCE CARS THAT ARE GUARANTEED TO IMPRESS. WHETHER YOU’RE LOOKING TO RENT A LAMBORGHINI, FERRARI, MCLAREN, OR ANY OTHER EXOTIC VEHICLE, WE’VE GOT YOU COVERED. OUR FLEET IS METICULOUSLY MAINTAINED TO ENSURE THAT EVERY CAR IS IN TOP CONDITION, BOTH MECHANICALLY AND AESTHETICALLY. WE TAKE PRIDE IN OUR ATTENTION TO DETAIL AND OUR COMMITMENT TO PROVIDING OUR CLIENTS WITH THE BEST POSSIBLE EXPERIENCE. OUR TEAM CONSISTS OF CAR ENTHUSIASTS WHO SHARE A LOVE FOR THESE BEAUTIFUL MACHINES. WE UNDERSTAND WHAT IT MEANS TO EXPERIENCE THE THRILL OF DRIVING A SUPERCAR AND WE’RE DEDICATED TO SHARING THAT EXPERIENCE WITH OUR CLIENTS.WE OFFER FLEXIBLE RENTAL OPTIONS TO SUIT YOUR NEEDS, WHETHER IT’S FOR A DAY, A WEEK, OR EVEN LONGER. WE ALSO PROVIDE DELIVERY AND PICK-UP SERVICES FOR YOUR CONVENIENCE. OUR GOAL IS TO MAKE YOUR RENTAL EXPERIENCE AS SEAMLESS AND HASSLE-FREE AS POSSIBLE, SO YOU CAN FOCUS ON ENJOYING YOUR RIDE. AT OUR COMPANY, WE’RE NOT JUST RENTING CARS, WE’RE PROVIDING MEMORIES. CONTACT US TODAY TO BOOK YOUR DREAM RIDE AND EXPERIENCE THE THRILL OF DRIVING A SUPERCAR LIKE NEVER BEFORE.
                        </p>
                    </div>
                </div>
            </div>
            <div class="fancy-feature-sixteen pt-100 md-pt-60" style={{ background: '#000' }}>
                <div class="container">
                    <div class="block-style-eighteen">
                        <h1 data-aos="fade-up" data-aos-duration="1200" class='fs-24 font-montserrat'>
                            our recent feature
                        </h1>
                        <br />
                        <div class="row align-items-center" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-5" data-aos="fade-up" data-aos-duration="1200">
                                <div class="d-flex align-items-center justify-content-center">
                                    <img width="500" src="../../monaco_assets/viral.jpg" alt="Image" />
                                </div>
                            </div>

                            <div class="col-lg-7" data-aos="fade-up" data-aos-duration="1200">
                                <div class="font-montserrat cl-white">
                                    <p style={{ paddingTop: "10px", fontSize: '14px' }}>
                                        VIRAL CHOKSHI
                                    </p>
                                    <p style={{ paddingTop: "10px", fontSize: '14px' }}>
                                        FOUNDER, MONACO EXPERIENCES / @MONACOEXPERIENCES
                                    </p>
                                    <p style={{ paddingTop: "10px", fontSize: '14px' }}>
                                        SIX YEARS AGO, VIRAL CHOKSHI NOTICED A VOID IN THE CAR RENTAL INDUSTRY WHEN HIS BROTHER WAS GETTING MARRIED. AT THE TIME, LUXURY CARS WERE ONLY AVAILABLE FOR RENT FOR AN ENTIRE WEEKEND RATHER THAN PER EVENT OR BY THE HOUR. CHOKSHI RECOGNIZED A NICHE MARKET, AND WITH THE PURCHASE OF HIS FIRST FERRARI, MONACO EXPERIENCES WAS BORN. HE NOW HAS A FEET OF OVER TEN EXOTIC VEHICLES AVAILABLE TO RENT FOR WEDDINGS AND OTHER SPECIAL EVENTS. A SELF-DESCRIBED PASSION PROJECT, CHOKSHI SAID THE NAME REFLECTS THE GLITZ AND GLAMOUR OF MONACO. “WHEN YOU THINK OF MONACO, YOU THINK OF BEAUTIFUL PEOPLE, EXOTIC CARS, WEALTH, AND PRESTIGE,” HE SAID. “I WANTED OUR COMPANY TO REFLECT THAT IMAGE AS WELL.”
                                    </p>
                                    <p style={{ paddingTop: "10px", fontSize: '14px' }}>
                                        TODAY, THE BUSINESS OWNER SAID MONACO EXPERIENCES RECEIVES INQUIRIES FROM ALL OVER THE COUNTRY, SPANNING EVERYTHING FROM MILLION-DOLLAR BUGATTIS TO ELEPHANTS. TO STAY ON TOP OF TRENDS AND CLIENT INTERESTS, HE’S CONSTANTLY UPDATING HIS FEET OF EXCLUSIVE VEHICLES. IT’S A TRUE CONCIERGE EXPERIENCE, TOO, CHOKSHI POINTED OUT. HE ENSURES THAT THE CLIENT CHOOSES A CAR THAT WON’T CLASH WITH THEIR OUTFIT, AN IMPORTANT ASPECT WHEN CHOOSING A LUXURY VEHICLE FOR AN EVENT. HE NOTED THAT MONACO’S SUCCESS HAS SO FAR BEEN ATTRIBUTED TO WORD OF MOUTH, BUT HE IS FOCUSED ON REACHING A BROADER AUDIENCE AND GROWING THE BRAND EVEN MORE.
                                    </p>
                                    <p style={{ paddingTop: "10px", fontSize: '14px' }}>
                                        SOURCE : INDUSTRYMAG.COM
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
