import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useHistory } from 'react-router-dom';
import { localRoute } from "../../../routes/localRoutes";

export default function Testimonial() {
    const history = useHistory();

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form1");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    
    return (
        <div className="mt-100 md-mt-100">
            <div className="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/homepage.webp)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '80vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '80%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-40 fw-700" style={{ color: '#fff' }}>best exotic car rentals in new jersey </h1>
                        <h2 className="font-montserrat fs-25 fw-600 mt-10 mb-10" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <button
                            className="font-montserrat enquire-button fw-700 "
                            onClick={handleOpenEnquiry}
                        >
                            inquire now &nbsp;
                            <img src="../../../../monaco_assets/InquireNow.png" alt="Inquire Now" style={{ height: '1rem' }} />
                        </button>
                    </div>

                </div>

                <div className="container">
                    <h2 className="font-montserrat mt-10 brands fw-500">
                        lamborghini | ferrari | rolls royce | mclaren | bentley | porsche
                    </h2>
                </div>
            </div>

            <style jsx>{`
                .enquire-button {
                    cursor: pointer;
                    background: #fff;
                    border-radius: 10px;
                    margin: 10px 0;
                    border: none;
                    padding: 10px 20px;
                    font-size: 1.25rem;
                    display: flex;
                    align-items: center;
                    color: #000;
                }

                .brands {
                    text-align: center;
                    color: #fff;
                    font-size: 1.5rem;
                }

                @media (max-width: 768px) {
                    .enquire-button {
                        padding: 8px 16px;
                        font-size: 1rem;
                    }

                    .brands {
                        text-align: center;
                        font-size: 1.25rem;
                    }
                }

                @media (max-width: 480px) {

                    .enquire-button {
                        padding: 6px 12px;
                        font-size: 0.875rem;
                    }

                    .brands {
                        font-size: 10px;
                    }
                }
            `}</style>
        </div>
    );
}
