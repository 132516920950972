import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function Audi() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/audi-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/audi-2.png",
        "../../../../monaco_assets/audi-3.png",
        "../../../../monaco_assets/audi-4.png",
        "../../../../monaco_assets/audi-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent r8 audi",
            answer: "at monaco experiences, audi r8 rental prices vary  based on the season and rental duration.please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true
        },
        {
            id: 1,
            question: "do you offer delivery and pickup services for the audi r8 ?",
            answer: "yes, we offer delivery and pickup services for your convenience please contact us to arrange thes services and discuss any associated fees.",
            isCollapsed: true
        }
        ,
        {
            id: 2,
            question: "what are the requirements for audi r8 rental ?",
            answer: "to rent audi r8, you must be at lea st 25 yearsold, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true
        }
        ,
        {
            id: 3,
            question: "are there any mileae restrictions when hiring the audi r8 ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true
        }
        ,
        {
            id: 4,
            question: "what happens if the audi r8 is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you  be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true
        }
        ,
        {
            id: 5,
            question: "where can i find audi r8 rental near me? ",
            answer: "monaco experiences r8 rental services in los angeles, new ork, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Audi R8 Rental in NJ,NY,LA | Monaco Experiences Exotic Car Rental
                </title>
                <meta name="description" content="Rent an Audi R8 from Monaco Experiences for a high-performance wedding or special occasion. Enjoy power, precision & style. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/audi-r8-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo7.png" alt="Monaco Experiences logo" style={{ height: '35px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>AUDI R8 </span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                5.2l v10  |  602 horsepower  |  actual msrp: $ 230,290
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            audi r8 rental - the most powerful v10 from audi
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            get ready to feel the adrenaline rush with the audi r8, the only v10 car from audi and the stunning ride driven by <span class="fw-700">tony stark</span> in the iron man movies. at monaco experiences, we're thrilled to offer you the chance to drive this <span class="fw-700">legendary supercar</span>. whether you’re planning a grand entrance at your wedding, looking to impress at a special event, or simply want to <span class="fw-700">experience the thrill</span> of a lifetime, our audi r8 rental is the perfect choice.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            imagine gliding down the highway in the sleek and aerodynamic audi r8, with its luxurious interior cradling you in comfort and style. our <span class="fw-700">flexible rental packages</span> at monaco experiences make it easy for you to get behind the wheel of this extraordinary vehicle, whether it’s for a <span class="fw-700">day</span>, a <span class="fw-700">weekend</span>, or <span class="fw-700">longer</span>.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            there’s nothing quite like making an entrance in an audi r8. plus, with its stunning design, you can take photos from any angle and <span class="fw-700">capture unforgettable moments.</span>
                        </p>


                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            experience the thrill with <span class="fw-700">the only spyder you need</span> – the audi r8! perfect for weddings, photoshoots, or a luxurious self-drive. book now and <span class="fw-700">turn heads wherever you go!</span> 😎
                        </p>
                    </div>
                </div>
            </div>

            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental audi r8  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
