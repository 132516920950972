import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { localRoute } from "../../routes/localRoutes";
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function Header(props) {
    const navRef = React.useRef(null);
    const history = useHistory()
    const currentRoute = useHistory().location.pathname.toLowerCase();

    const handleBackMansions = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.mansions)
        window.scrollTo(0, 0)
    };

    const handleBackHome = () => {
        history.push(localRoute.home)
        window.scrollTo(0, 0)
    };

    const handleExoticFleet = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.exoticFleet)
        window.scrollTo(0, 0)
    };

    const handleWhoWeAre = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.whoWeAre)
        window.scrollTo(0, 0)
    };

    const onToggleClick = (e) => {
        navRef.current.classList.toggle("show");
    };

    const handleWeddingService = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.exoticCarRentalForWedding)
    };

    const handlePhotoshootService = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.exoticCarRentalForPhotoshoot)
    };

    const handleMusicService = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.exoticCarRentalForMusicVideo)
    };

    const handleSelfDrivenService = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.selfDrivenExoticCarRental)
    };

    const handleBaratService = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.services)
    };

    const handleVintageService = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.vintageCarRental)
    };

    const handleRentalPhiladelphia = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.philadelphia)
    };

    const handleRentalNJ = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.newJersey)
    };

    const handleRentalNY = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.newYork)
    };
    const handleRentalWDC = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.washington)
    };

    const handleRentalMedellin = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.medellin)
    };

    const handleRentalCancun = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.cancun)
    };
    const handleRentalLA = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.LosAngeles)
    };
    const handleRentalPR = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.puerto)
    };
    const handleRentalBoston = () => {
        navRef.current.classList.toggle("show");
        history.push(localRoute.location)
    };

    const [serviceDropdown, setServiceDropdown] = useState(true);
    const [locationDropdown, setLocationDropdown] = useState(false);

    const handleServiceDropDown = () => {
        setServiceDropdown(prevState => !prevState);
        if (!serviceDropdown) {
            setLocationDropdown(false);
        }
    };

    const handleLocationDropDown = () => {
        setLocationDropdown(prevState => !prevState);
        if (!locationDropdown) {
            setServiceDropdown(false);
        }
    };


    return (
        <div class="theme-main-menu sticky-menu" style={{ backgroundColor: '#000000' }}>
            <div className='space-between-for-navbar' style={{ display: 'flex', alignItems: 'center' }}>
                <div class="logo">
                    <a style={{ cursor: "pointer" }} onClick={handleBackHome}>
                        <img class="monaco-logo" src="../../../../monaco_assets/monaco-footer.png" alt="monaco-logo" />
                    </a>
                </div>
                <nav id="mega-menu-holder" class="navbar navbar-expand-lg" >
                    <div class="container nav-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <button class="navbar-toggler navbar-toggler-right" style={{ padding: 0 }} onClick={onToggleClick} type="button" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="flaticon-menu-of-three-lines"></i>
                        </button>

                        {/* display on desktop view */}
                        <div className='show-on-desktop-view'>
                            <div class="navbar-collapse collapse" ref={navRef} >

                                <div class="font-montserrat d-lg-flex justify-content-between align-items-center">
                                    <ul class="fw-500 font-montserrat navbar-nav">

                                        <li class="nav-item position-static active">
                                            <a style={{ cursor: "pointer" }} onClick={handleExoticFleet} class="nav-link">
                                                <span  className={currentRoute == "/exotic-fleet" ? "tabactive" : ""}>OUR EXOTIC FLEET</span>
                                            </a>
                                        </li>

                                        <li className="nav-item position-static active dropdown">
                                            <a style={{ cursor: 'pointer' }} className="nav-link dropbtn">
                                                <span style={{ display: 'flex' }}>
                                                    SERVICES
                                                    <ArrowDropDownIcon fontSize='small' />
                                                </span>
                                            </a>

                                            <div class="dropdown-content">
                                                <a style={{ cursor: 'pointer' }} onClick={handleWeddingService}>
                                                    <span className={currentRoute == "/exotic-car-rental-for-wedding" ? "tabactive" : ""}>
                                                        exotic car rental for wedding
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handlePhotoshootService}>
                                                    <span className={currentRoute == "/exotic-car-rental-for-photoshoot" ? "tabactive" : ""}>
                                                        exotic car rental for photoshoot
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleMusicService}>
                                                    <span className={currentRoute == "/exotic-car-rental-for-music-video" ? "tabactive" : ""}>
                                                        exotic car rental for music video
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleSelfDrivenService}>
                                                    <span className={currentRoute == "/self-driven-exotic-car-rental" ? "tabactive" : ""}>
                                                        exotic self driven rental car
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleBaratService}>
                                                    <span className={currentRoute == "/mobile-baraat-experiences" ? "tabactive" : ""}>
                                                        baraat experience van
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleVintageService}>
                                                    <span className={currentRoute == "/vintage-car-rental" ? "tabactive" : ""}>
                                                        vintage car rental
                                                    </span>
                                                </a>
                                            </div>
                                        </li>

                                        <li className="nav-item position-static active dropdown">
                                            <a style={{ cursor: 'pointer' }} className="nav-link dropbtn">
                                                <span style={{ display: 'flex' }}>
                                                    LOCATION
                                                    <ArrowDropDownIcon fontSize='small' />
                                                </span>
                                            </a>

                                            <div class="dropdown-content">
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalNJ}>
                                                    <span className={currentRoute == "/exotic-car-rental-new-jersey" ? "tabactive" : ""}>
                                                        exotic car rental new jersey
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalNY}>
                                                    <span className={currentRoute == "/exotic-car-rental-new-york" ? "tabactive" : ""}>
                                                        exotic car rental new york
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalPhiladelphia}>
                                                    <span className={currentRoute == "/exotic-car-rental-philadelphia" ? "tabactive" : ""}>
                                                        exotic car rental philadelphia
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalBoston}>
                                                    <span className={currentRoute == "/exotic-car-rental-boston" ? "tabactive" : ""}>
                                                        exotic car rental boston
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalWDC}>
                                                    <span className={currentRoute == "/exotic-car-rental-washington-dc" ? "tabactive" : ""}>
                                                        exotic car rental washington dc
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalLA}>
                                                    <span className={currentRoute == "/exotic-car-rental-los-angeles" ? "tabactive" : ""}>
                                                        exotic car rental los angeles
                                                    </span>
                                                </a>
                                                {/* <a style={{ cursor: 'pointer' }} onClick={handleRentalPR}>
                                                    <span className={currentRoute == "/exotic-car-rental-puerto-rico" ? "tabactive" : ""}>
                                                        exotic car rental puerto rico
                                                    </span>
                                                </a> */}
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalCancun}>
                                                    <span className={currentRoute == "/exotic-car-rental-cancun" ? "tabactive" : ""}>
                                                        exotic car rental cancun
                                                    </span>
                                                </a>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRentalMedellin}>
                                                    <span className={currentRoute == "/exotic-car-rental-medellin" ? "tabactive" : ""}>
                                                        car rental medellin
                                                    </span>
                                                </a>
                                            </div>
                                        </li>

                                        <li class="nav-item position-static active">
                                            <a style={{ cursor: "pointer" }} onClick={handleWhoWeAre} class="nav-link">
                                                <span  className={currentRoute == "/who-we-are" ? "tabactive" : ""}>WHO WE ARE</span>
                                            </a>
                                        </li>

                                        <li class=" nav-item position-static active">
                                            <a style={{ cursor: "pointer" }} onClick={handleBackMansions} class="nav-link">
                                                <span className={currentRoute == "/house-of-monaco" ? "tabactive" : ""}>HOUSE OF MONACO</span>
                                            </a>
                                        </li>

                                        <li class="nav-item position-static active">
                                            <a href="tel:+18623659250" style={{ cursor: "pointer" }} class="nav-link">
                                                <span >
                                                    +1.862.365.9250
                                                </span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* display on Mobile view */}
                        <div className='show-on-mobile-view'>
                            <div class="navbar-collapse collapse" ref={navRef}>
                                <div class="font-montserrat d-lg-flex justify-content-between align-items-center">
                                    <ul class="fw-500 font-montserrat navbar-nav" style={{ alignItems: 'flex-start' }}>

                                        <li class="nav-item position-static active">
                                            <a style={{ cursor: "pointer", padding: '3px 18px 3px 15px', fontSize: '18px' }} onClick={handleExoticFleet} class="nav-link">
                                                <span className={currentRoute == "/exotic-fleet" ? "tabactive" : ""}>our exotic fleet</span>
                                            </a>
                                        </li>


                                        <ul className="nav-item position-static active dropdown" style={{ width: '100%' }}>
                                            <a style={{ cursor: 'pointer', padding: '3px 18px 3px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '18px' }} className="nav-link" onClick={handleServiceDropDown}>
                                                <p>
                                                    services
                                                </p>
                                                <img style={{ fontSize: '10px' }} src="../../../../monaco_assets/down-arrow.svg" />
                                            </a>

                                            {serviceDropdown == true &&
                                                <div style={{ color: '#B88B2B', gap: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '15px' }}>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleWeddingService} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-for-wedding" ? "tabactive" : ""}>
                                                            exotic car rental for wedding
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handlePhotoshootService} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-for-photoshoot" ? "tabactive" : ""}>
                                                            exotic car rental for photoshoot
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleMusicService} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-for-music-video" ? "tabactive" : ""}>
                                                            exotic car rental for music video
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleSelfDrivenService} className="fs-16">
                                                        <span className={currentRoute == "/self-driven-exotic-car-rental" ? "tabactive" : ""}>
                                                            exotic self driven rental car
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleBaratService} className="fs-16">
                                                        <span className={currentRoute == "/mobile-baraat-experiences" ? "tabactive" : ""}>
                                                            baraat experience van
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleVintageService} className="fs-16">
                                                        <span className={currentRoute == "/vintage-car-rental" ? "tabactive" : ""}>
                                                            vintage car rental
                                                        </span>
                                                    </a>
                                                </div>
                                            }
                                        </ul>


                                        <ul className="nav-item position-static active dropdown" style={{ width: '100%' }}>
                                            <a style={{ cursor: 'pointer', gap: 5, padding: '3px 18px 3px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '18px' }} className="nav-link" onClick={handleLocationDropDown}>
                                                <p>
                                                    location
                                                </p>
                                                <img style={{ fontSize: '10px' }} src="../../../../monaco_assets/down-arrow.svg" />
                                            </a>

                                            {locationDropdown == true &&
                                                <div style={{ color: '#B88B2B', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '15px' }}>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalNJ} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-new-jersey" ? "tabactive" : ""}>
                                                            exotic car rental new jersey
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalNY} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-new-york" ? "tabactive" : ""}>
                                                            exotic car rental new york
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalPhiladelphia} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-philadelphia" ? "tabactive" : ""}>
                                                            exotic car rental philadelphia
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalBoston} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-boston" ? "tabactive" : ""}>
                                                            exotic car rental boston
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalWDC} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-washington-dc" ? "tabactive" : ""}>
                                                            exotic car rental washington dc
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalLA} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-los-angeles" ? "tabactive" : ""}>
                                                            exotic car rental los angeles
                                                        </span>
                                                    </a>
                                                    {/* <a style={{ cursor: 'pointer' }} onClick={handleRentalPR} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-puerto-rico" ? "tabactive" : ""}>
                                                            exotic car rental puerto rico
                                                        </span>
                                                    </a> */}
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalCancun} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-cancun" ? "tabactive" : ""}>
                                                            exotic car rental cancun
                                                        </span>
                                                    </a>
                                                    <a style={{ cursor: 'pointer' }} onClick={handleRentalMedellin} className="fs-16">
                                                        <span className={currentRoute == "/exotic-car-rental-medellin" ? "tabactive" : ""}>
                                                            car rental medellin
                                                        </span>
                                                    </a>
                                                </div>
                                            }
                                        </ul>

                                        <li class="nav-item position-static active">
                                            <a style={{ cursor: "pointer", padding: '3px 18px 3px 15px', fontSize: '18px' }} onClick={handleWhoWeAre} class="nav-link">
                                                <span className={currentRoute == "/who-we-are" ? "tabactive" : ""}>who we are</span>
                                            </a>
                                        </li>

                                        <li class=" nav-item position-static active">
                                            <a style={{ cursor: "pointer", padding: '3px 18px 3px 15px', fontSize: '18px' }} onClick={handleBackMansions} class="nav-link">
                                                <p className={currentRoute == "/house-of-monaco" ? "tabactive" : ""}>house of monaco</p>
                                            </a>
                                        </li>

                                        <li class="nav-item position-static active">
                                            <a target="_blank" href="mailto:info@monacoexperiences.com" style={{ color: '#fff', padding: '3px 18px 3px 15px', fontSize: '18px' }} class="nav-link">info@monacoexperiences.com</a>
                                        </li>

                                        <li class="nav-item position-static active">
                                            <a href="tel:+18623659250" style={{ cursor: "pointer", color: '#fff', padding: '3px 18px 3px 15px', fontSize: '18px' }} class="nav-link fs-25">+1.862.365.9250</a>
                                        </li>
                                    </ul>


                                </div>
                                <ul class="d-flex mt-10" style={{ justifyContent: 'center', gap: '10px' }}>
                                    <a target="_blank" href="https://instagram.com/monacoexperiences?igshid=NTc4MTIwNjQ2YQ==" style={{ color: '#000', background: '#fff', borderRadius: '50%', fontSize: '25px', height: 35, width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                    <a target="_blank" href="https://www.facebook.com/monacoexperiences/?mibextid=LQQJ4d" style={{ color: '#000', background: '#fff', borderRadius: '50%', fontSize: '22px', height: 35, width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a target="_blank" href="https://wa.link/49oo39" style={{ color: '#000', background: '#fff', borderRadius: '50%', fontSize: '25px', height: 35, width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><i class="fa fa-whatsapp"></i></a>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>
                <div class="nav-item  position-static active show-on-desktop-view">
                    <ul class="d-flex">
                        <li><a target="_blank" href="https://instagram.com/monacoexperiences?igshid=NTc4MTIwNjQ2YQ==" style={{ color: '#B88B2B', marginRight: '15px', fontSize: '20px' }}><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a target="_blank" href="https://www.facebook.com/monacoexperiences/?mibextid=LQQJ4d" style={{ color: '#B88B2B', marginRight: '15px', fontSize: '20px' }}><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a target="_blank" href="https://wa.link/49oo39" style={{ color: '#B88B2B', marginRight: '15px', fontSize: '20px' }}><i class="fa fa-whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
