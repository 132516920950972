import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function Mclaren720s() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/mclaren-720s-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/mclaren-720s-2.png",
        "../../../../monaco_assets/mclaren-720s-3.png",
        "../../../../monaco_assets/mclaren-720s-4.png",
        "../../../../monaco_assets/mclaren-720s-5.png",
    ])


    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a mclaren?",
            answer: "the cost to rent a mclaren varies based on the season and rental duration. for specific pricing on the mclaren 720s, please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true
        },
        {
            id: 1,
            question: "do you offer delivery and pickup services for the mclaren?",
            answer: "yes, we offer delivery and pickup services for your convenience. please contact us to arrange these services and discuss any associated fees.",
            isCollapsed: true
        },
        {
            id: 2,
            question: "what are the requirements to rent a mclaren?",
            answer: "to rent a mclaren, you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true
        },
        {
            id: 3,
            question: "are there any mileage restrictions when hiring the mclaren 720s ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true
        },
        {
            id: 4,
            question: "what happens if the mclaren is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you  be liable for repair costs up to the amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true
        },
        {
            id: 5,
            question: "where can i find mclaren rentals near me?",
            answer: "monaco experiences provides mclaren rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Rent a Mclaren in NJ,NY,LA from Monaco Experiences Exotic Car Rental
                </title>
                <meta name="description" content="Get  a customised quote, Feel the thrill of a McLaren 720S in NJ, NY, LA with Monaco Experiences for your wedding or special occasion. Make a grand entrance." />
                <meta name="keywords" content="mclaren rental ,mclaren rentals, mclaren 720s rental, mclaren car rental, rent a mclaren " />
                <link rel="canonical" href="https://monacoexperiences.com/mclaren-720s-rental" />
            </Helmet>
            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-end justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo11.png" alt="Image" style={{ height: '30px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>MCLAREN 720S</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                4l twin turbo v8  |  720 horsepower  |  actual msrp : $ 326,500
                            </p>
                        </div>
                    </div>
                    <div style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry}>
                        <span class="font-montserrat fs-20" style={{ padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </div>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            McLaren rental - the ultimate sports car by Monaco Experiences
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            unleash the ultimate driving experience with our mclaren 720s rental. this isn't just any mclaren - our 720s comes in the <span class="fw-700">rare</span> and stunning aztec <span class="fw-700">gold</span> color, <span class="fw-700">one of only three</span> in the united states.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            whether you're looking to rent a mclaren for a special event, a weekend getaway, or to make your wedding day even more memorable, we offer premier rentals that guarantee an unforgettable journey. our mclaren 720s is perfect for those who crave speed, <span class="fw-700">luxury</span>, and <span class="fw-700">unparalleled performance.</span>
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            with its sleek design, <span class="fw-700">full carbon fiber</span> body that exudes richness, and a powerful engine, this supercar stands out on any road. don't just dream about driving a supercar; <span class="fw-700">make it a reality</span>. our flexible rental packages make accessing this elite vehicle easy, allowing you to enjoy the pinnacle of automotive engineering.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            discover why the mclaren 720s is a favorite among <span class="fw-700">car enthusiasts</span> and experience the adrenaline rush firsthand. choose the exclusive mclaren and elevate your driving adventure today.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            contact us to drive in style! for more ideas and inspiration, explore our instagram page feel the exhilaration of driving a car that shares its dna with <span class="fw-700">formula 1 champions</span> 🏎️💨
                        </p>
                    </div>
                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury cars rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>

            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
