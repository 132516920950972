import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const PhotoScrolling1 = () => {
    const history = useHistory();

    var settings = {
        dots: false,
        fade: false,
        infinite: true,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        initialSlide: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            }
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <div class="container" style={{ maxWidth: '100%' }}>
            <div class='row'>
                <div class='col-lg-12'>
                    <Slider {...settings}>
                        <div class='col-lg-12'>
                            <div>
                                <img src="../../../../monaco_assets/houseofmonacocar-1.png" alt="Image" class="image-meta" />
                            </div>
                        </div>

                        <div class='col-lg-12'>
                            <div>
                                <img src="../../../../monaco_assets/houseofmonacocar-2.png" alt="Image" class="image-meta" />
                            </div>
                        </div>

                        <div class='col-lg-12'>
                            <div>
                                <img src="../../../../monaco_assets/houseofmonacocar-3.png" alt="Image" class="image-meta" />
                            </div>
                        </div>

                        <div class='col-lg-12'>
                            <div>
                                <img src="../../../../monaco_assets/houseofmonacocar-4.png" alt="Image" class="image-meta" />
                            </div>
                        </div>

                        <div class='col-lg-12'>
                            <div>
                                <img src="../../../../monaco_assets/houseofmonacocar-6.png" alt="Image" class="image-meta" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default PhotoScrolling1;