import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function Chevrolet() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/chevrolet-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/chevrolet-2.png",
        "../../../../monaco_assets/chevrolet-3.png",
        "../../../../monaco_assets/chevrolet-4.png",
        "../../../../monaco_assets/chevrolet-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much to rent a corvette",
            answer: "at monaco experiences, corvette rental prices vary based on the season and rental duration. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "do you offer delivery and pickup services for the corvette?",
            answer: "yes, we offer delivery and pickup services for your convenience. please contact us to arrange these services and discuss any associated fees.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "what are the requirements for corvette rental ?",
            answer: "to rent a corvette, you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "are there any mileage restrictions when hiring the corvette ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the corvette is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you  be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "where can i find corvette rental near me?",
            answer: "monaco experiences provides corvette car rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    New Corvette Rental by Monaco Experiences,Exotic Car Rental
                </title>
                <meta name="description" content="Corvette rental for weddings and special occasions, available in NJ,NY,LA & all over US. Contact us for a customised quotation" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/chevrolet-corvette-stingray-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo1.png" alt="Image" style={{ width: '40px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '3px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>CHEVROLET CORVETTE</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                6.2l v8  |  490 horsepower  |  actual msrp : $ 82,400
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 8px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            steal the show: rent a corvette supercar today
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            experience the thrill of driving an <span class="fw-700">iconic sports car</span> with monaco experiences corvette rental services. whether you're seeking to rent a sports car for a special occasion or just want to indulge in a high-performance driving experience, our fleet features the stunning chevrolet corvette stingray, a masterpiece of design and engineering that guarantees to <span class="fw-700">turn heads.</span>
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            with monaco experiences, renting a corvette is seamless and exhilarating. our corvette car rental service offers you the opportunity to <span class="fw-700">feel the power</span> of this legendary vehicle. <span class="fw-700">imagine</span> cruising through the streets in a car that embodies speed, <span class="fw-700">style, and sophistication.</span> our chevrolet corvette rentals are meticulously maintained to ensure you enjoy the ultimate driving experience.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            <span class="fw-700">don't settle for ordinary</span> when you can <span class="fw-700">rent a corvette</span> and make a statement. perfect for weddings, photoshoots, or simply treating yourself, we ensure you drive away in luxury, choose monaco experiences for your corvette  car rental and experience unmatched performance and elegance.
                        </p>


                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            <span class="fw-700">turn heads wherever you go!</span> book your corvette rental now and indulge in the thrill of <span class="fw-700">driving a legend</span>. monaco experiences turns your automotive <span class="fw-700">dreams into reality, effortlessly!</span> 🤌
                        </p>
                    </div>
                </div>
            </div>

            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury car rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
