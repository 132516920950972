import React, { useEffect, useState } from "react";
import { localRoute } from '../../../routes/localRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function StoriesFromCustomer() {
    const history = useHistory()


    var settings = {
        dots: false,
        fade: false,
        infinite: true,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        initialSlide: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            }
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])



    return (
        <div>
            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        stories from our customers
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        exceptional feedback from customers renting exotic cars from us
                    </p>
                </div>

                <div class="container">
                    <Slider {...settings}>

                        <div>
                            <div class='col-lg-12 mt-30' style={{ height: 400 }}>
                                <div style={{ background: '#242424', display:'flex',flexDirection:'column',justifyContent: 'space-between', height: 370, padding: '20px', borderRadius: '6px', position: 'relative' }}>
                                    <p class="font-montserrat text-center" style={{ color: '#fff' }}>
                                        we booked the white lambo huracan for our wedding last minute and our experience with monaco & viral far exceeded our expectations. we will be using monaco again and would recommend to anyone looking for an exotic luxury vehicle experience. you will not be disappointed!! thank you again!!
                                    </p>
                                    <img src="../../../../monaco_assets/quama.png" style={{ position: 'absolute', bottom: '-6%', zIndex: '1', left: '50%', transform: 'translateX(-50%)', height: '40px' }} alt="Half Inside Half Outside Image" />
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <span class="font-montserrat fs-25" style={{ color: '#B88B2B', fontWeight: '600' }}>sukhjit mangat</span>
                                        <span class="font-montserrat fs-16 mb-20" style={{ color: '#B88B2B' }}>new jersey</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class='col-lg-12 mt-30' style={{ height: 400 }}>
                                <div style={{ background: '#242424', display:'flex',flexDirection:'column',justifyContent: 'space-between', height: 370, padding: '20px', borderRadius: '6px', position: 'relative' }}>
                                    <p class="font-montserrat text-center" style={{ color: '#fff' }}>
                                        used monaco for my baraat and the car was amazing! they have a wide range of vehicles from new sport cars to classic vintage cars and the team works with you to make sure you get the exact vehicle you want for your big day. highly recommend them for your baraat and any rentals you may need!
                                    </p>
                                    <img src="../../../../monaco_assets/quama.png" style={{ position: 'absolute', bottom: '-6%', zIndex: '1', left: '50%', transform: 'translateX(-50%)', height: '40px' }} alt="Half Inside Half Outside Image" />
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <span class="font-montserrat fs-25" style={{ color: '#B88B2B', fontWeight: '600' }}>tatsat parekh</span>
                                        <span class="font-montserrat fs-16 mb-20" style={{ color: '#B88B2B' }}>new jersey</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>

                            <div class='col-lg-12 mt-30' style={{ height: 400 }}>
                                <div style={{ background: '#242424', display:'flex',flexDirection:'column',justifyContent: 'space-between', height: 370, padding: '20px', borderRadius: '6px', position: 'relative' }}>
                                    <p class="font-montserrat text-center" style={{ color: '#fff' }}>
                                        amazing fleet, amazing customer service, true luxury experience from start to finish. i dealt with viral who was super attentive to all my needs on vehicle pickup and drop off, he even went out of his way to pickup the vehicle from my wedding venue. i would highly recommend them to anyone interested in their luxury/exotic vehicle offerings. thank you for everything !
                                    </p>
                                    <img src="../../../../monaco_assets/quama.png" style={{ position: 'absolute', bottom: '-6%', zIndex: '1', left: '50%', transform: 'translateX(-50%)', height: '40px' }} alt="Half Inside Half Outside Image" />
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <span class="font-montserrat fs-25" style={{ color: '#B88B2B', fontWeight: '600' }}>joseph pitney</span>
                                        <span class="font-montserrat fs-16 mb-20" style={{ color: '#B88B2B' }}>new jersey</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>

                            <div class='col-lg-12 mt-30' style={{ height: 400 }}>
                                <div style={{ background: '#242424', display:'flex',flexDirection:'column',justifyContent: 'space-between', height: 370, padding: '20px', borderRadius: '6px', position: 'relative' }}>
                                    <p class="font-montserrat text-center" style={{ color: '#fff' }}>
                                        worked with viral to book a car for my wife’s entrance to our wedding. the service was amazing and the car (a vintage porsche speedster) was gorgeous. virals team stayed late for us to be able to take photos with the car and test drive it ourselves too.
                                    </p>
                                    <img src="../../../../monaco_assets/quama.png" style={{ position: 'absolute', bottom: '-6%', zIndex: '1', left: '50%', transform: 'translateX(-50%)', height: '40px' }} alt="Half Inside Half Outside Image" />
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <span class="font-montserrat fs-25" style={{ color: '#B88B2B', fontWeight: '600' }}>benny xing</span>
                                        <span class="font-montserrat fs-16 mb-20" style={{ color: '#B88B2B' }}>new jersey</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>

                            <div class='col-lg-12 mt-30' style={{ height: 400 }}>
                                <div style={{ background: '#242424', display:'flex',flexDirection:'column',justifyContent: 'space-between', height: 370, padding: '20px', borderRadius: '6px', position: 'relative' }}>
                                    <p class="font-montserrat text-center" style={{ color: '#fff' }}>
                                        thank you viral for an amazing baraat experience-as a bride i had a vision for what i wanted especially because we did a dual baraat and it ended up being the best thing ever with the help of viral and the amazing cars . thank you for making our vision come true and allowing us to stand out with the perfect cars-you guys are the best!!!! and thank you for being so easy to work with. 10/10 recommend!
                                    </p>
                                    <img src="../../../../monaco_assets/quama.png" style={{ position: 'absolute', bottom: '-6%', zIndex: '1', left: '50%', transform: 'translateX(-50%)', height: '40px' }} alt="Half Inside Half Outside Image" />
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <span class="font-montserrat fs-25" style={{ color: '#B88B2B', fontWeight: '600' }}>akil kalaparambhat</span>
                                        <span class="font-montserrat fs-16 mb-20" style={{ color: '#B88B2B' }}>new jersey</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>
        </div>
    )
}