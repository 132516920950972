import React, { useEffect } from "react";
import { localRoute } from '../../../routes/localRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function GrowWithUs() {
    const history = useHistory();

    var settings = {
        dots: false,
        fade: false,
        infinite: true,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        initialSlide: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            }
        ]
    };

    const handleGallery = () => {
        history.push(localRoute.gallery)
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])

    return (
        <div class="mt-30 font-montserrat ">

            <div class="container mb-20" style={{ maxWidth: '100%' }}>
                <div class='row'>
                    <div class='col-lg-12'>
                        <Slider {...settings}>
                            <div class='col-lg-12'>
                                <div>
                                    <img src="../../../../monaco_assets/social1.png" alt="Image" class="image-meta" />
                                </div>
                            </div>

                            <div class='col-lg-12'>
                                <div>
                                    <img src="../../../../monaco_assets/social2.png" alt="Image" class="image-meta" />
                                </div>
                            </div>

                            <div class='col-lg-12'>
                                <div>
                                    <img src="../../../../monaco_assets/social3.png" alt="Image" class="image-meta" />
                                </div>
                            </div>

                            <div class='col-lg-12'>
                                <div>
                                    <img src="../../../../monaco_assets/social4.png" alt="Image" class="image-meta" />
                                </div>
                            </div>

                            <div class='col-lg-12'>
                                <div>
                                    <img src="../../../../monaco_assets/social5.png" alt="Image" class="image-meta" />
                                </div>
                            </div>

                            <div class='col-lg-12'>
                                <div>
                                    <img src="../../../../monaco_assets/social6.png" alt="Image" class="image-meta" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>

            <div class='col-lg-12'>
                <div class="section-heading text-center " data-aos="fade-up" data-aos-duration="1200">
                    <div class="d-flex mb-10" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <a target="_blank" href="https://instagram.com/monacoexperiences?igshid=NTc4MTIwNjQ2YQ==" style={{ color: '#B88B2B', marginRight: '5px' }}>
                            <span style={{ display: 'inline' }}>
                                <i class="fa fa-instagram" aria-hidden="true"></i>
                            </span>
                            <span class="font-montserrat fs-20 fw-600" style={{ color: '#ffffff', display: 'inline' }}>
                                &nbsp;visit- @monacoexperiences for latest updates
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}