import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validationBusiness';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import axios from 'axios';
import moment from 'moment';

const init = {
  subscriber_fname: '',
  subscriber_lname: '',
  subscriber_email: '',
  subscriber_phone: '',
  subscriber_location: '',
  subscriber_cars: '',
  subscriber_venue: '',
  subscriber_date: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );


// const validateGmail = (email) =>
// String(email)
//   .match(
//     'gmail'
//   );



const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function GetInTouch() {
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);

  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [number, setNumber] = useState([])
  const [location, setLocation] = useState('')
  const [singleCar, setSingleCar] = useState('')
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  // const cars = ['AUDI R8 V10', 'BAARAT EXPERIENCE', 'BENTLEY GTC', 'BENTLEY BENTYAGA', 'BMW M4 COMPETITION', 'CORVETTE', 'EXCALIBUR (VINTAGE)', ' FERRARI 488', 'LAMBORGHINI HURACAN', '1956 PORSCHE SPEEDSTER', 'ROLLS ROYCE DAWN', 'MCLAREN 720S']

  // const locations = ['New York', 'New Jersey', 'Philadelphia', 'Washington D.C', 'Boston', 'Los Angeles', 'Cancun', 'Puerto Rico']
  const [locations, setLocations] = useState([]);
  const [cars, setCars] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {

    setSelectedDate(date);
    setState({
      ...state,
      'subscriber_date': date,
    });
  };

  const handleClose = () => {
    setErrors(false)
    setState(init);
    setOpen(false)
  }

  // if(state.subscriber_email.match("gmail")){
  //   errors.subscriber_email = 'Please enter official email';
  // }

  const handleChangeNumberLeadInput = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }

  const handleChangeLocation = (event) => {
    const onlyLocation = event.target.value;
    console.log('eventevent1', event.target)
    console.log('eventevent2', event.target.name)

    setLocation(onlyLocation)
    setState({
      ...state,
      'subscriber_location': onlyLocation,
    });

  }

  const handleChangeCars = (event) => {
    const onlyCar = event.target.value;
    console.log('eventevent1', event.target.value)
    console.log('eventevent2', event.target.name)

    setSingleCar(onlyCar)
    setState({
      ...state,
      'subscriber_cars': onlyCar,
    });

  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));
    if (
      state.subscriber_fname !== '' &&
      state.subscriber_lname !== '' &&
      state.subscriber_email !== '' &&
      state.subscriber_venue !== '' &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.subscriber_location !== '' &&
      state.subscriber_cars !== '' &&
      state.subscriber_date !== ''
    ) {
      const formattedDate = moment(state.subscriber_date).format('YYYY-MM-DD');

      const postData = {
        first_name: state.subscriber_fname,
        last_name: state.subscriber_lname,
        email: state.subscriber_email,
        phone: state.subscriber_phone,
        venue: state.subscriber_venue,
        location: state.subscriber_location,
        cars: [state.subscriber_cars],
        date: formattedDate
      };
      console.log('Create Subscriber Detail', postData)
      // createSubscriber(postData)(subscriberDispatch)
      axios.post('https://api.monacoexperiences.com/api/v1/enquiries/', postData)
      setState(init);
      setOpen(true);
      setSingleCar('');
      setLocation('');
    }
  }


  useEffect(() => {
    // fetch locations from https://api.monacoexperiences.com/api/v1/locations/
    axios.get('https://api.monacoexperiences.com/api/v1/locations/')
      .then(res => {
        setLocations(res.data.results)
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])

  useEffect(() => {
    // fetch cars from https://api.monacoexperiences.com/api/v1/cars/ 
    axios.get('https://api.monacoexperiences.com/api/v1/cars/')
      .then(res => {
        setCars(res.data.results)
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Form Successfully Submitted!
        </Alert>
      </Snackbar>

      <div class="consultation-form" style={{ background: '#242424' }}>

        <Grid container padding={2} xs={12}>

          <Grid container padding={1} xs={12} sm={6} md={6} lg={6}>
            <div class='mt-10 mb-30' style={{ marginLeft: '10px' }}>
              <p class='font-montserrat fs-25 fw-700 cl-white'>get in touch</p>
              <p class='font-montserrat fs-20 fw-500 cl-white'>we are here for you feel free to reach out</p>
            </div>
            <Grid padding={1} xs={12}>
              <TextField fullWidth label="NAME"
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'
                  },
                }} variant="outlined" type="text" name="subscriber_fname" value={state.subscriber_fname} onChange={handleChange} />
              {errors.subscriber_fname && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_fname}</p>}
            </Grid>

            <Grid padding={1} xs={12}>
              <TextField fullWidth label="EMAIL"
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'

                  },
                }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
              {errors.subscriber_email && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_email}</p>}
            </Grid>

            <Grid padding={1} xs={12}>
              <TextField fullWidth label="MESSAGE"
                size='small'
                rows={4}
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "10px",
                  },
                }}
                inputProps={{
                  style: {
                    background: '#fff',
                    padding: '10px'
                  },
                }} variant="outlined" type="text" name="subscriber_venue" value={state.subscriber_venue} onChange={handleChange} />
              {errors.subscriber_venue && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_venue}</p>}
            </Grid>

            <Grid padding={1} xs={12}>
              <button style={{ fontWeight: "600", background: '#B88B2B', color: '#000' }} onClick={handleClick} type="submit" class="font-montserrat template-btn">submit</button>
            </Grid>

          </Grid>

          <Grid container xs={12} sm={6} md={6} lg={6}>

            <Grid container padding={1} xs={12} style={{ justifyContent: 'center' }}>
              <img src="../../../../monaco_assets/logo.png" className='mt-30' style={{ height: '130px' }} />
            </Grid>

            <Grid container padding={1} xs={12} style={{ justifyContent: 'center' }}>
              <div>
                <div class='mt-20'>
                  <a className='font-montserrat mt-10' href="tel:+18623659250" style={{ display: 'flex',alignContent:'center',flexWrap:'nowrap', textAlign: 'center' }}>
                    <img src="../../../../monaco_assets/call.png" alt="" style={{ height: '30px' }} />
                    <p class="cl-white font-montserrat" style={{ marginLeft: '15px', lineHeight:1.5 }}>+1 862-365-9250</p>
                  </a>
                </div>

                <div class='mt-20'>
                  <a target="_blank" href="https://instagram.com/monacoexperiences?igshid=NTc4MTIwNjQ2YQ==" style={{ display: 'flex',alignContent:'center',flexWrap:'nowrap', textAlign: 'center' }}>
                    <img src="../../../../monaco_assets/insta.png" alt="" style={{ height: '30px' }} />
                    <p class="cl-white font-montserrat" style={{ marginLeft: '15px', lineHeight:1.5 }}>@monacoexperiences</p>
                  </a>
                </div>

                <div class='mt-20'>
                  <a className='font-montserrat mt-10' target="_blank" href="mailto:info@monacoexperiences.com" style={{ display: 'flex',alignContent:'center',flexWrap:'nowrap', textAlign: 'center' }}>
                    <img src="../../../../monaco_assets/email.png" alt="" style={{ height: '30px' }} />
                    <p class="cl-white font-montserrat" style={{ marginLeft: '15px', lineHeight:1.5 }}>info@monacoexperiences.com</p>
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
