import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Location from "../../components/location";
const LocationPage = React.memo((props) => {
  return (
    <>
      <Location />
    </>
  );
});

export default withRouter(LocationPage);
