import React, { useEffect, useState, useContext, } from "react";
import { getBlogs } from "../../../context/actions/blogAction/getBlogs";

import { GlobalContext } from '../../../context/GlobalState'
import { localRoute } from '../../../routes/localRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
import { format } from 'date-fns';
import parse from 'html-react-parser'
import { useParams, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ExoticRentalCar() {
    const history = useHistory()
    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])



    const handleBackMobileBaraat = () => {
        history.push(localRoute.services)
        window.scrollTo(0, 0)
    };

    const handleBackAudi = () => {
        history.push(localRoute.audiRental)
        window.scrollTo(0, 0)
    };

    const handleBackBMW = () => {
        history.push(localRoute.bmwCompetitionRental)
        window.scrollTo(0, 0)
    };

    const handleBackBentleyBentayga = () => {
        history.push(localRoute.bentleyBentaygaRental)
        window.scrollTo(0, 0)
    };

    const handleBackBentleyGTCRental = () => {
        history.push(localRoute.bentleyGTCRental)
        window.scrollTo(0, 0)
    };

    const handleBackChevrolet = () => {
        history.push(localRoute.chevroletRental)
        window.scrollTo(0, 0)
    };

    const handleBackFarrari458 = () => {
        history.push(localRoute.farrari458Rental)
        window.scrollTo(0, 0)
    };

    const handleBackFarrariF8 = () => {
        history.push(localRoute.cars)
        window.scrollTo(0, 0)
    };

    const handleBackLamborghini = () => {
        history.push(localRoute.lamborghiniHuracanRental)
        window.scrollTo(0, 0)
    };

    const handleBackLamborghiniEvo = () => {
        history.push(localRoute.lamborghiniHuracanEvoRental)
        window.scrollTo(0, 0)
    };

    const handleBackRollsRoyce = () => {
        history.push(localRoute.rollsRoyceDawnRental)
        window.scrollTo(0, 0)
    };

    const handleBackMclaren = () => {
        history.push(localRoute.mclaren720sRental)
        window.scrollTo(0, 0)
    };

    const handleBackPorsche = () => {
        history.push(localRoute.porscheSpeedster1956Rental)
        window.scrollTo(0, 0)
    };


    const handleBackCars = () => {
        history.push(localRoute.exoticFleet)
        window.scrollTo(0, 0)
    };


    const handleOpenEnquiry = () => {
        window.location.replace("/#enquiry-form1");
    }


    const handleBackAstonMartin = () => {
        history.push(localRoute.astonMartinRental)
        window.scrollTo(0, 0)
    };

    const handleBackRollsRoyceWraith = () => {
        history.push(localRoute.rollsRoyceWraithRental)
        window.scrollTo(0, 0)
    };

    return (
        <div class="font-montserrat" data-aos="fade-up" data-aos-duration="1200">

            <div class="container">
                <div class="row">

                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/mclaren.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackMclaren} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo11.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        MCLAREN 720S
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackMclaren}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/Huracan-3.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackLamborghini} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo4.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        LAMBORGHINI HURACAN 610-4
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackLamborghini}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/porsche.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackPorsche} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo9.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        PORSCHE SPEEDSTER 1956
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackPorsche}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/bentley.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackBentleyGTCRental} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo2.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        BENTLEY GTC
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackBentleyGTCRental}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/ferrari-f8.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackFarrariF8} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo8.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        FERRARI F8 SPIDER
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackFarrariF8}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/ferrari-458.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackFarrari458} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo8.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        FERRARI 458 SPIDER
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackFarrari458}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/aston-martin.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackAstonMartin} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/aston-martin-logo.png" alt="Image" style={{ height: '15px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        ASTON MARTIN DB11
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackAstonMartin}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/rolls-royce-wraith.png" alt="Image" style={{ borderRadius: '5px', cursor: 'pointer' }} onClick={handleBackRollsRoyceWraith} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo5.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        ROLLS ROYCE WRAITH
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackRollsRoyceWraith}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/rollsRoyce.png" alt="Image" style={{ borderRadius: '5px',cursor:'pointer' }} onClick={handleBackRollsRoyce} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo5.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        ROLLS ROYCE DAWN
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackRollsRoyce}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class='col-lg-6 mt-20' >
                        <div>
                            <img src="../../../../monaco_assets/evo.png" alt="Image" style={{ borderRadius: '5px',cursor:'pointer' }} onClick={handleBackLamborghiniEvo} />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="../../../../monaco_assets/logo4.png" alt="Image" style={{ height: '25px' }} />
                                    <span class="font-montserrat fs-10 fw-500" style={{ color: '#fff', marginLeft: '5px' }}>
                                        LAMBORGHINI EVO
                                    </span>
                                </div>
                                <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more">
                                    <span style={{ margin: 'auto', fontSize: '15px', fontWeight: '500', padding: '4px 20px 6px', color: '#000' }} onClick={handleBackLamborghiniEvo}>inquire</span>
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div class="text-center mt-40">
                <h2 class="font-montserrat fs-24 fw-600" style={{ color: '#fff' }}>
                    <a onClick={handleBackCars} style={{ cursor: 'pointer' }}>
                        &lt; view all vehicles &gt;
                    </a>
                </h2>
            </div>
        </div>
    )
}
