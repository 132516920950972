import React, { useEffect, useState, useContext, } from "react";
import { localRoute } from '../../../routes/localRoutes';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';


export default function Blog(props) {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const history = useHistory()

    const handleOpenBlog = (blog_slug) => {
        history.push(`${localRoute.blogDetail}/${blog_slug}`);
        console.log("blog_slug 11", blog_slug)
    };
    ;

    useEffect(() => {
        AOS.init({ duration: 2000 });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://api.monacoexperiences.com/api/v1/articles/category/blog/?limit=10");
                if (!response.ok) {
                    throw new Error("Failed to fetch blogs");
                }
                const data = await response.json();
                setBlogs(data.results);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Trending car rental Blogs by Monaco Experiences
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/blogs" />
            </Helmet>

            <div style={{ background: '#111111' }}>
                <div class="font-montserrat feature-blog-one blog-page-bg mt-100" data-aos="fade-up" data-aos-duration="1200">

                    <div class="container section-heading text-center mb-40">
                        <h2 class="font-montserrat fs-30 fw-700" style={{ color: '#ffffff' }}>
                            explore the latest insights of exotic rental cars and luxury living here!
                        </h2>
                    </div>

                    <img src="../../../../monaco_assets/blogsMain.png" alt="Image" style={{ width: '100%' }} />

                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress style={{ color: '#B88B2B' }} />
                        </div>
                    ) : (
                        <>
                            <div class="container text-center mt-40">
                                <h2 class="font-montserrat fs-24 fw-600 mb-20" style={{ color: '#B88B2B' }}>
                                    <a style={{ cursor: 'pointer', color: '#fff' }}>
                                        quick tags
                                    </a>
                                </h2>
                                <div >
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>excalibur</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>rolls royce wedding trends</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>lamborghini</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>top 10 exotic rental cars in us</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>photoshoot</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>new wedding trends</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>how much does a ferrari cost?</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>why vintage cars are trending</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>photoshoot</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>what is baarat experience ?</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>perfect budget exotic car</span>
                                </div>
                            </div>

                            <div class="container">
                                <div class="row">

                                    {blogs.map(blog => (
                                        <div className="col-lg-4 mt-50" key={blog.id}>
                                            <div onClick={() => handleOpenBlog(blog.slug)} style={{ cursor: 'pointer' }}>
                                                <img src={blog.cover} alt="Blog Thumbnail" />
                                                <div style={{ marginTop: '10px' }}>
                                                    <p className="font-montserrat fs-16 fw-300 cl-white">
                                                        {moment(blog.updated_at).format('ll')}
                                                    </p>
                                                    <p className="font-montserrat fs-22 fw-600 cl-white">{blog.title}</p>
                                                    <p className="font-montserrat fs-18 fw-300 cl-white mt-10">{blog.short_body}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div class="container text-center mt-40">
                                <h2 class="font-montserrat fs-24 fw-600 mb-20" style={{ color: '#B88B2B' }}>
                                    <a style={{ cursor: 'pointer', color: '#fff' }}>
                                        quick tags
                                    </a>
                                </h2>
                                <div >
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>excalibur</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>rolls royce wedding trends</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>lamborghini</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>top 10 exotic rental cars in us</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>photoshoot</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>new wedding trends</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>how much does a ferrari cost?</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>why vintage cars are trending</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>photoshoot</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>what is baarat experience ?</span>
                                    <span style={{ margin: '10px 5px', background: '#fff', color: '#000', padding: '5px 8px 7px', borderRadius: '5px' }}>perfect budget exotic car</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
