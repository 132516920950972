import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";

export default function Philadelphia(props) {

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of luxury cars are available for rent in philadelphia?",
            answer: "we offer a wide range of luxury cars, including lamborghini huracan, ferrari f8 spyder, rolls royce dawn, bentley gtc, mclaren and more to check our full exotic cars fleet click here",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why moncao experiences is the best exotic car rental services in philadelphia?",
            answer: "at monaco experiences, we offer top-notch exotic car rental services in philadelphia, featuring luxury brands like lamborghini, ferrari, rolls royce, and more. our exceptional customer service and premium car selection makes the best choice for your exotic car rental needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "do you provide baraat packages in philadelphia?",
            answer: "yes, we provide specialized baraat packages in philadelphia. our unique baraat experience mobile van is equipped with a tv, smoke gun, dj, lights, and a beer/wine tank, perfect for indian weddings. contact us to customize your baraat package.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i get a luxury car rental for a wedding in philadelphia?",
            answer: "yes, we offer luxury car rentals for weddings in philadelphia to make your special day extraordinary. choose from our wide selection of luxury cars to add a touch of elegance to your wedding. check our full exotic cars fleet here",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Exotic Car Rental Philadelphia by Monaco Experiences
                </title>
                <meta name="description" content=" Get the best quote for exotic and luxury car rentals in Philadelphia. Choose from Ferrari, Lamborghini, and McLaren for weddings, events, and special occasions. Drive in luxury today!" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-philadelphia" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/philadelphia.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental philadelphia</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>
                </div>

                <div style={{ background: '#111' }}>
                    <div class="container pt-30 pb-30">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-30 fw-800 text-center" style={{ color: '#fff' }}>
                                    live the vip life with exotic car rentals philadelphia
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                unleash the ultimate expression of <span className="fw-700">luxury</span> and <span className="fw-700">power</span> in philadelphia with monaco experiences, your one-stop shop for philly exotic car rentals. our exclusive collection of luxury car rental philadelphia elevates every moment, transforming your trip into an <span className="fw-700">extraordinary odyssey</span>.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                cruise through philadelphia's historic streets and iconic landmarks in unparalleled style. from the untamed spirit of a ferrari to the refined <span className="fw-700">elegance of a bentley</span>, our diverse fleet caters to your desires. we offer the finest philadelphia car rentals, seamlessly blending convenience with <span className="fw-700">unmatched luxury</span>.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                monaco experiences makes philly exotic car rental effortless. our streamlined booking process and dedicated customer support ensure a <span className="fw-700">smooth</span> and <span className="fw-700">satisfying</span> experience from the moment you <span className="fw-700">reserve your dream car</span>.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                whether you're celebrating a special occasion or indulging in a luxurious escape, monaco experiences is your perfect partner. explore the "city of brotherly love" in <span className="fw-700">unparalleled comfort</span> and style. every drive becomes <span className="fw-700">extraordinary</span> behind the wheel of our <span className="fw-700">top-of-the-line vehicles</span>.
                            </p>
                        </div>
                    </div>
                </div>

                <LogoScrolling />

                <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                    <div class="container section-heading text-center">
                        <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                            available exotic cars in philadelphia
                        </h2>
                        <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                            classy and luxury rental cars in <span style={{ fontWeight: 700 }}>philadelphia</span> : discover our unique <span style={{ fontWeight: 700 }}>showroom experience.</span>
                        </p>
                    </div>
                    <ExoticRental16Car extraText={"RENTAL IN PHILADELPHIA"} />
                </div>


                <DriveTheDream extraText={"in philadelphia"} />

                <StoriesFromCustomer />
                <div style={{ background: '#111' }}>
                    <div style={{ background: '#111' }}>
                        <div className="container pt-30 pb-30">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                    frequently asked questions
                                </h2>
                                <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                    these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                                </p>
                            </div>

                            {faqItems.map((item) => (
                                <div key={item.id} className="container mt-40">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3
                                            className="font-montserrat fs-20 fw-500"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer' }}
                                        >
                                            {item.question}
                                        </h3>
                                        <h3
                                            className="font-montserrat"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                        >
                                            {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                        </h3>
                                    </div>
                                    <hr style={{ borderColor: '#BABABA' }} />
                                    {!item.isCollapsed && (
                                        <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
