import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticRental16Car from "components/home/exoticRental16Car/ExoticRental16Car";


export default function ExoticCarRentalForMusicVideo() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent an exotic car for a music video?",
            answer: "the cost to rent an exotic car for a music video varies depending on the model, duration, and specific requirements of your shoot. please contact monaco experiences for a customized quote tailored to your needs.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what types of luxury car rentals available for music video?",
            answer: "our collection caters to various music genres, ensuring the perfect match for your creative vision. check our car fleet to explore all the options available.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "can i extend my rental period if the shoot takes longer than expected?",
            answer: "yes, you can extend your rental period if the shoot takes longer than expected, subject to vehicle availability and additional charges.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Supercars rental For Music Video NJ,NY,LA | Monaco Experiences
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="lamborghini for rent, lambo rental, rent a lamborghini, rent lamborghini, lamborghini huracan for rent, lamborghini rental, lamborghini rentals" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-for-music-video" />
            </Helmet>

            <div class="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/exotic-car-rental-for-music.svg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '85vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental for music video</h1>
                        <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                    </div>
                </div>
            </div>


            <div class="container mt-30">
                <div class="row" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mb-10">
                        <h2 class="font-montserrat fs-30 fw-600 mb-20" style={{ color: '#fff', textAlign: 'center', lineHeight: '1' }}>
                            make your music video pop with exotic car rentals
                        </h2>
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../../monaco_assets/music-video-1.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>
                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            renting an exotic car for your music video can significantly elevate the production quality and create a <span style={{ fontWeight: 700 }}>lasting impression</span>. at monaco experiences, our fleet of luxury vehicles, including the lamborghini huracan, ferrari f8 spyder, and rolls royce dawn, offers the perfect blend of <span style={{ fontWeight: 700 }}>style and sophistication</span>.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            exotic car rental for music video projects adds a layer of visual appeal that <span style={{ fontWeight: 700 }}>captures</span> viewers' attention and keeps them engaged. the sleek design, powerful engines, and luxurious interiors of our high-end cars provide <span style={{ fontWeight: 700 }}>dynamic visuals</span> that can enhance various themes, from modern and edgy to classic and elegant.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            incorporating a luxury car rental for music video can also convey <span style={{ fontWeight: 700 }}>themes of success</span>, ambition, and luxury, <span style={{ fontWeight: 700 }}>resonating</span> with your audience on a deeper level. our all vehicles ensure reliability and top performance, allowing you to <span style={{ fontWeight: 700 }}>focus</span> on creativity without worrying about logistics.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            choosing monaco experiences ensures <span style={{ fontWeight: 700 }}>seamless service</span>, flexible rentals, and <span style={{ fontWeight: 700 }}>trust</span>. make a memorable impact on your audience with an exotic car from monaco experiences.
                        </p>
                    </div>
                    <div class="col-lg-12 mt-20">
                        <img src="../../../../monaco_assets/music-video-2.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff', textAlign: 'center' }}>
                            we have exotic car rentals for all music genre
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            bring your music video to life with monaco experiences' exotic car rentals. did you know that over <span style={{ fontWeight: 700 }}>70%</span> of the most-watched music videos worldwide feature <span style={{ fontWeight: 700 }}>stunning exotic cars?</span> make your video unforgettable with our luxurious fleet, perfect for <span style={{ fontWeight: 700 }}>any genre.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            imagine a lamborghini huracan turbocharging your hip-hop hit, or a rolls royce dawn adding elegance to your soulful ballad. our diverse collection caters to every musical style, ensuring your video radiates sophistication and flair.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            exotic cars are the <span style={{ fontWeight: 700 }}>secret ingredient</span> that elevates music videos from ordinary to <span style={{ fontWeight: 700 }}>iconic</span>. from rock anthems to pop sensations, our meticulously maintained vehicles deliver the <span style={{ fontWeight: 700 }}>visual punch</span> that sets your production apart. transform your music video into a visual masterpiece with monaco experiences. let us help you captivate your audience and leave a lasting impression. your music deserves the best, and we deliver it with <span style={{ fontWeight: 700 }}>unparalleled style.</span>
                        </p>
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/music-video-4.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/music-video-3.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/music-video-5.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rental
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car extraText={`RENTAL FOR MUSIC VIDEO`} />
            </div>


            <DriveTheDream />

            <Contact />

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}
