import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function BentleyBentayga() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/bentley-bentayga-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/bentley-bentayga-2.png",
        "../../../../monaco_assets/bentley-bentayga-3.png",
        "../../../../monaco_assets/bentley-bentayga-4.png",
        "../../../../monaco_assets/bentley-bentayga-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a bentley?",
            answer: "at monaco experiences, bentley rental prices vary based on the season and rental duration. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "do you offer delivery and pickup services for the bentley?",
            answer: "yes, we offer delivery and pickup services for your convenience. please contact us to arrange these services and discuss any associated fees.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "what are the requirements for bentley rental ?",
            answer: "to rent a bentley from monaco experiences, you need a valid driver’s license, proof of insurance, and a security deposit. age restrictions may apply. fill up the contact form or call us for more details",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "are there any mileage restrictions when hiring the bentley?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the bentley is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "where can i find bentley rental near me?",
            answer: "bentley rentals available at monaco experiences across various locations like la, new york, boston, philadelphia, and beyond. additionally, reach out to us for delivery options to your preferred location, available upon request.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Bentley Rental For Wedding - Monaco Experiences - Exotic Car Rental
                </title>
                <meta name="description" content="Indulge in luxury with a Bentley Bentayga rental from Monaco Experiences for weddings or any event. Enjoy elegance and comfort. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px' }}>
                        <img src="../../../../monaco_assets/logo2.png" alt="Image" style={{ height: '30px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>BENTLEY BENTAYGA</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                4.l turbocharged v8  |  542 horsepower  |  0-60  4.4sec  |  actual msrp : $ 263,000
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minheight: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            5 seats of luxury : rent bentley and experience elegance
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            when it comes to luxury and <span class="fw-700">elegance on the road</span>, nothing compares to the bentley bentayga rental from monaco experiences. known for its unparalleled craftsmanship and sophisticated design, renting a bentley  offers an experience like no other. with <span class="fw-700">five seats of pure luxury</span>, you and your passengers will enjoy the utmost comfort and style during your journey.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            opting to rent a bentley from monaco experiences ensures you are driving a vehicle that stands out in any setting. our bentley bentayga, in the stunning <span class="fw-700">orange flame color</span>, is sure to turn heads and make a <span class="fw-700">bold statement</span>. whether you're attending a special event, going on a business trip, or simply indulging in a lavish drive, a bentley rental is the perfect choice. its <span class="fw-700">powerful performance</span>, combined with its <span class="fw-700">exquisite interior</span>, sets it apart from other vehicles.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            for those looking to rent a luxury car, the bentley bentayga offers more than just transportation; it provides an unforgettable experience. if you seek the <span class="fw-700">ultimate in comfort</span> and <span class="fw-700">elegance</span>, consider to rent a luxury car like the bentley bentayga from monaco experiences.
                        </p>


                        <p class="font-montserrat fw-700 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            experience power & refinement: get behind the wheel of a bentley✨
                        </p>
                    </div>
                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury cars rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}