import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import Experience from "components/home/experience";
import axios from "axios";
import house_of_monaco from '../../assets/images/house_of_monaco.svg'
import ContactHouse from "components/home/contact/ContactHouse";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import AskQuestion from "components/home/askQuestion";
import PhotoScrolling from "./PhotoScrolling";
import PhotoScrolling1 from "./PhotoScrolling1";

export default function Mansions() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of vehicles do you offer for rent in medellín?",
            answer: "at house of monaco, we offer an exclusive chevrolet traverse for rent. this spacious and comfortable suv is perfect for families and groups, providing a smooth and luxurious driving experience while exploring medellín.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "is early check-in available for the luxury mansion rental in medellin?",
            answer: "yes, we offer early check-in options for our luxury mansion rentals in medellin, subject to availability. please contact us in advance to arrange and confirm your preferred check-in time.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "how many people can the house of monaco accommodate?",
            answer: "the house of monaco can comfortably accommodate up to 10 guests. with multiple bedrooms, ample living space, and luxurious bathrooms, it is perfect for any gatherings",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form-monaco");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Luxury Villa Rental in Medellin, Colombia by House of Monaco
                </title>
                <meta name="description" content="Experience luxury redefined in our Medellin villa. Enjoy breathtaking panoramic views of the city from your private infinity pool. House of Monaco offers the ultimate escape. Book your unforgettable stay now! " />
                <meta name="keywords" content="car rental medellin colombia, rental car medellin, best places to stay in medellin,best area to stay in medellin" />
                <link rel="canonical" href="https://monacoexperiences.com/house-of-monaco" />
            </Helmet>

            <div class="container mt-120 mb-30 md-mt-120" data-aos="fade-up" data-aos-duration="1200" >

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/manssion-logo.png" alt="Image" style={{ height: '45px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '3px' }}>
                            <p>
                                <h1 class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>
                                    CASA MIRA - THE HOUSE OF MONACO
                                </h1>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                5 bedrooms  |  infinity pool  |  home in the heavens
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px' }} class="read-more" onClick={handleOpenEnquiry}>
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 10px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>

                <div class="row mt-20 align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src='../../../../monaco_assets/houseofmonaco-2.png' style={{ borderRadius: '5px' }} />
                            </div>

                        </div>
                    </div>
                </div>


                <div class="row align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mt-20 mb-20">
                        <h2 class="font-montserrat fs-40 fw-700" style={{ color: '#fff', textAlign: 'center' }}>
                            casa mira : luxury mansion rental in medellín
                        </h2>
                    </div>
                    <div class="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/houseofmonaco-1.png" alt="Image" />
                    </div>
                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20" style={{ color: '#fff' }}>
                            experience unparalleled <span className="fw-700">luxury</span> and <span className="fw-700">comfort</span> at house of monaco, the premier mansion for rent in medellín. this exquisite property boasts four spacious bedrooms, each designed with elegance and <span className="fw-700">relaxation</span> in mind. the villa features an infinity pool that offers breathtaking <span className="fw-700">panoramic views of medellín.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            the modern kitchen is fully equipped with state-of-the-art appliances. the villa also provides beautifully <span className="fw-700">landscaped outdoor spaces,</span> perfect for hosting unforgettable gatherings.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            house of monaco also offers <span className="fw-700">lavish living areas</span> with luxurious furnishings, our personalized concierge service ensures all your needs are met, from arranging transportation to booking exclusive experiences. book house of monaco today and discover the ultimate in luxury villa rental in medellín. enjoy a truly <span className="fw-700">extraordinary stay</span> in one of the finest properties in the region, <span className="fw-700">located in the best area</span> to stay in medellín. with stunning panoramic views of medellín, house of monaco stands out as one of the best places to stay in medellín, offering an unparalleled blend of <span className="fw-700">luxury</span>, <span className="fw-700">comfort</span>, and <span className="fw-700">natural beauty</span>.
                        </p>
                    </div>
                    <div class="col-lg-12 mt-20">
                        <img src="../../../../monaco_assets/houseofmonaco-3.png" alt="Image" />
                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff' }}>
                            best stay for your medellin vacay : experience luxury living
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            stay in style with one of the best stays in medellin—our luxury mansion rental. just a few minutes from the city center, this beautiful mansion offers both convenience and peace. from its high perch, you can enjoy stunning views of all of medellin.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our mansion features elegant interiors, modern amenities, and spacious living areas. relax by the  infinity pool, dine on the stylish dining room, or unwind in one of the comfortable bedrooms. every moment here is designed for your comfort.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            perfect for vacations, special events, or a luxurious escape with your beloved ones, our medellin mansion offers an luxury stay. see medellin from the top and enjoy the best views and amenities. book your stay today!
                        </p>
                    </div>
                </div>
            </div>


            <PhotoScrolling />

            <div class="container mb-30" >
                <div class="row align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mt-30 mb-30">
                        <h2 class="font-montserrat fs-40 fw-700" style={{ color: '#fff', textAlign: 'center' }}>
                            our premium car rental services in medellín
                        </h2>
                        <p class="font-montserrat fs-25 fw-300 mt-10" style={{ color: '#fff', textAlign: 'center' }}>
                            explore medellín with monaco transportation - featuring our exclusive chevrolet traverse
                        </p>
                    </div>

                    <div class="col-lg-6" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/houseofmonaco-6.png" alt="Image" style={{ height: '460px' }} />
                    </div>
                    <div class="col-lg-6">
                        <p class="font-montserrat fw-300 fs-22" style={{ color: '#fff' }}>
                            experience the best car rental in medellín, colombia with monaco transportation. we offer our exclusive chevrolet traverse, ensuring you <span className="fw-700">travel in style</span> and <span className="fw-700">comfort</span>. whether you need a vehicle for a special occasion or a day of exploring, our chevrolet traverse provides ample space, advanced features, and a smooth ride, making it ideal for families and groups.
                        </p>
                        <p class="font-montserrat fw-300 fs-22 mt-20" style={{ color: '#fff' }}>
                            we also offer convenient car rental medellín airport services, making your arrival and departure <span className="fw-700">seamless</span>.
                        </p>
                        <p class="font-montserrat fw-300 fs-22 mt-20" style={{ color: '#fff' }}>
                            discover the vibrant city of medellín and its <span className="fw-700">stunning surroundings</span> with ease and elegance. book your car rental today and enjoy an unparalleled driving experience with monaco transportation.
                        </p>
                    </div>
                </div>
            </div>
            <PhotoScrolling1 />

            <div class='col-lg-12 mt-20'>
                <div class="section-heading text-center " data-aos="fade-up" data-aos-duration="1200">
                    <div class="d-flex mb-10" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <a target="_blank" href="https://www.instagram.com/monacotransportation/" style={{ color: '#fff', marginRight: '5px' }}>
                            <span style={{ display: 'inline' }}>
                                <i class="fa fa-instagram" aria-hidden="true"></i>
                            </span>
                            <span class="font-montserrat fs-20 fw-600" style={{ color: '#ffffff', display: 'inline' }}>
                                &nbsp;visit- @monacotransportation
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <ContactHouse />

            {/* <div className="container">
                <div className="row">

                    <div class="col-lg-12 mt-10">
                        <h2 class="font-montserrat fs-30 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            direction to casa mira
                        </h2>
                        <p class="font-montserrat fs-25 fw-300 mt-10" style={{ color: '#fff', textAlign: 'center' }}>
                            explore medellín with monaco transportation - featuring our exclusive chevrolet traverse
                        </p>

                    </div>

                    <div class="col-lg-3">
                    </div>
                    <div class="col-lg-6 mb-30">
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d263392.7460685172!2d-117.99488968578711!3d34.19756010467926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c329c466d70ea9%3A0xe45ef44baa0981ea!2sWest%20Covina%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1718949822878!5m2!1sen!2sin"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                                aria-hidden="false"
                                tabIndex="0"
                            ></iframe>
                        </div>
                    </div>

                    <div class="col-lg-3">
                    </div>
                </div>
            </div> */}



            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
