import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import { localRoute } from '../../../routes/localRoutes';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import ShareIcon from '@mui/icons-material/Share';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';


export default function BlogDetails1() {
    const [blogDetail, setBlogDetail] = useState({});
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

    const { blog_slug } = useParams();
    const history = useHistory();

    const handleOpenBlog = (blog_slug) => {
        history.push(`${localRoute.blogDetail}/${blog_slug}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchBlog = async () => {
            try {
                const response = await fetch(`https://api.monacoexperiences.com/api/v1/articles/${blog_slug}/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setBlogDetail(data?.blog);
            } catch (error) {
                console.error('Error fetching blog:', error);
            }
        };
        fetchBlog();
    }, [blog_slug]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://api.monacoexperiences.com/api/v1/articles/category/blog/?limit=10");
                if (!response.ok) {
                    throw new Error("Failed to fetch blogs");
                }
                const data = await response.json();
                setBlogs(data.results);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const handleShareClick = () => {
        setIsSharePopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsSharePopupOpen(false);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`https://monacoexperiences.com${history.location.pathname}`)
            .then(() => alert('Link copied to clipboard!'))
            .catch(err => console.error('Failed to copy link:', err));
    };

    const SharePopup = () => (
        <div style={{
            position: 'absolute',
            top: '40px',
            right: '0',
            background: '#fff',
            padding: '10px',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
            zIndex: 1000,
            width: 200,
            borderRadius: '5px',
        }}>
            <p style={{ display: 'flex', alignItems: 'center' }}>
                <FacebookIcon style={{ marginRight: '5px', color: '#3b5998' }} />
                <a href={`https://www.facebook.com/sharer/sharer.php?u=https://monacoexperiences.com${history.location.pathname}`} target="_blank" rel="noopener noreferrer">Share on Facebook</a>
            </p>
            <p style={{ display: 'flex', alignItems: 'center' }}>
                <TwitterIcon style={{ marginRight: '5px', color: '#1DA1F2' }} />
                <a href={`https://twitter.com/intent/tweet?url=https://monacoexperiences.com${history.location.pathname}`} target="_blank" rel="noopener noreferrer">Share on Twitter</a>
            </p>
            <p style={{ display: 'flex', alignItems: 'center' }}>
                <LinkedInIcon style={{ marginRight: '5px', color: '#0077b5' }} />
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://monacoexperiences.com${history.location.pathname}`} target="_blank" rel="noopener noreferrer">Share on LinkedIn</a>
            </p>
            <p style={{ display: 'flex', alignItems: 'center' }}>
                <WhatsAppIcon style={{ marginRight: '5px', color: '#25D366' }} />
                <a href={`https://api.whatsapp.com/send?text=Check%20out%20this%20blog:%20https://monacoexperiences.com${history.location.pathname}`} target="_blank" rel="noopener noreferrer">Share on WhatsApp</a>
            </p>
            <p style={{ display: 'flex', alignItems: 'center' }}>
                <InstagramIcon style={{ marginRight: '5px', color: '#E4405F' }} />
                <a href={`https://www.instagram.com/direct/new/?text=Check%20out%20this%20blog:%20https://monacoexperiences.com${history.location.pathname}`} target="_blank" rel="noopener noreferrer">Share on Instagram</a>
            </p>
            <p style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'blue' }} onClick={handleCopyLink}>
                <LinkIcon style={{ marginRight: '5px', color: '#000' }} />
                Copy Link
            </p>
        </div>
    );


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{blogDetail.meta_title && parse(blogDetail.meta_title)}</title>
                <meta name="description" content={blogDetail.meta_description} />
                <meta name="keywords" content={blogDetail.meta_keywords} />
                <link rel="canonical" href={`https://monacoexperiences.com${history.location.pathname}`} />
            </Helmet>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress style={{ color: '#B88B2B' }} />
                </div>
            ) : (
                <>
                    <div className="mt-120 md-mt-120 font-montserrat container">
                        <div className="col-lg-12">
                            <div className="single-blog-post blog-details-content">
                                <h1 className="font-montserrat cl-white">
                                    {blogDetail.title && parse(blogDetail.title)}
                                </h1>
                                <p className="font-montserrat fs-20 mt-5 fw-400" style={{ color: '#E7E7E7' }}>
                                    {blogDetail.short_body && parse(blogDetail.short_body)}
                                </p>
                                <p className="font-montserrat fs-12 mt-5 fw-300" style={{ color: '#E7E7E7' }}>
                                    {moment(blogDetail.created_at).format('ll')}
                                </p>

                                <img src={blogDetail.cover} className="mt-10" alt="Blog Cover" />

                                <p className="font-montserrat fs-18 mt-20 fw-300" style={{ color: '#E7E7E7' }}>
                                    {blogDetail.body && parse(blogDetail.body)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container text-center">
                        <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'2px'}}>
                            <div className="font-montserrat cl-white">
                                share this blog
                            </div>
                            <ClickAwayListener onClickAway={handleClosePopup}>
                                <div style={{position: 'relative'}}>
                                    <ShareIcon fontSize="small" style={{color:'#B88B2B', cursor: 'pointer'}} onClick={handleShareClick} />
                                    {isSharePopupOpen && <SharePopup />}
                                </div>
                            </ClickAwayListener>
                        </div>

                        <h2 className="font-montserrat fs-24 fw-600 mb-20 mt-20" style={{ color: '#B88B2B' }}>
                            <a style={{ cursor: 'pointer', color: '#fff' }}>
                                trending tags
                            </a>
                        </h2>

                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center' }}>
                            {/* Your tags go here */}
                        </div>

                        <h2 className="font-montserrat fs-24 fw-600 mt-30" style={{ color: '#B88B2B' }}>
                            <a style={{ cursor: 'pointer', color: '#fff' }}>
                                explore other blogs & insights
                            </a>
                        </h2>
                        <hr style={{ background: 'white' }} />

                        <div className="container">
                            <div className="row">
                                {blogs.map(blog => (
                                    <div className="col-lg-4 mt-50" key={blog.id}>
                                        <div onClick={() => handleOpenBlog(blog.slug)} style={{ cursor: 'pointer' }}>
                                            <img src={blog.cover} alt="Blog Thumbnail" />
                                            <div style={{ marginTop: '10px' }}>
                                                <p className="font-montserrat fs-16 fw-300 cl-white">
                                                    {moment(blog.updated_at).format('ll')}
                                                </p>
                                                <p className="font-montserrat fs-22 fw-600 cl-white">{blog.title}</p>
                                                <p className="font-montserrat fs-18 fw-300 cl-white mt-10">{blog.short_body}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
