import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./MansionsStyle";
import Mansions from "../../components/mansions/index";
const MansionsPage = React.memo((props) => {
  return (
    <>
      <Mansions />
    </>
  );
});

export default withRouter(MansionsPage);
