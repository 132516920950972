import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";

export default function Puerto(props) {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of luxury cars are available for rent in puerto rico?",
            answer: "we offer a wide range of luxury cars, including lamborghini huracan, ferrari f8 spyder, rolls royce dawn, bentley gtc, mclaren and more to check our full exotic cars fleet click here",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why moncao experiences is the best exotic car rental services in puerto rico?",
            answer: "at monaco experiences, we offer top-notch exotic car rental services in puerto rico, featuring luxury brands like lamborghini, ferrari, rolls royce, and more. our exceptional customer service and premium car selection makes the best choice for your exotic car rental needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "do you provide baraat packages in puerto rico?",
            answer: "yes, we provide specialized baraat packages in puerto rico. our unique baraat experience mobile van is equipped with a tv, smoke gun, dj, lights, and a beer/wine tank, perfect for indian weddings. contact us to customize your baraat package.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i get a luxury car rental for a wedding in puerto rico?",
            answer: "yes, we offer luxury car rentals for weddings in puerto rico to make your special day extraordinary. choose from our wide selection of luxury cars to add a touch of elegance to your wedding. check our full exotic cars fleet here",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Luxury,Vintage Car Hire in Puerto Rico by Monaco Experiences
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-puerto-rico" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/puerto.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental puerto rico</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>

                </div>

                <div style={{ background: '#111' }}>
                    <div class="container pt-30 pb-30">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-30 fw-800 text-center" style={{ color: '#fff' }}>
                                    carpe diem! best exotic car rental in puerto rico
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                imagine yourself behind the wheel of a lamborghini huracan, navigating the scenic routes of puerto rico with unparalleled performance and <span className="fw-700">luxury</span>. at monaco experiences, we offer an <span className="fw-700">exceptional range</span> of exotic car rentals in puerto rico, designed to provide a <span className="fw-700">truly unforgettable</span> driving experience. our fleet features <span className="fw-700">top-of-the-line</span> models, including the ferrari f8 spyder and rolls royce dawn, all carefully maintained to ensure the highest standards of <span className="fw-700">excellence</span>.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                our luxury car rental service in puerto rico caters to a variety of needs, from <span className="fw-700">high-profile events</span> to professional photoshoots or simply indulging in a premium driving experience. whether you're looking to make a grand entrance in your wedding, <span className="fw-700">capture stunning photos</span>, or enjoy the thrill of driving a high-end car, monaco experiences is committed to delivering unparalleled service and the finest vehicles.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                book your dream car today with monaco experiences and discover why <span className="fw-700">we are the premier choice</span> for exotic car rentals in puerto rico. elevate your journey with us and <span className="fw-700">experience the extraordinary</span>. our exceptional service and luxury vehicles ensure an unforgettable experience on this beautiful island. seize the day and <span className="fw-700">drive in style</span>.
                            </p>
                        </div>
                    </div>
                </div>

                <LogoScrolling />

                <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                    <div class="container section-heading text-center">
                        <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                            available exotic cars in puerto rico
                        </h2>
                        <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                            classy and luxury rental cars in <span style={{ fontWeight: 700 }}>puerto rico</span> : discover our unique <span style={{ fontWeight: 700 }}>showroom experience.</span>
                        </p>
                    </div>
                    <ExoticRental16Car extraText={"RENTAL IN PUERTO RICO"} />
                </div>


                <DriveTheDream extraText={"in puerto rico"} />

                <StoriesFromCustomer />
                <div style={{ background: '#111' }}>
                    <div style={{ background: '#111' }}>
                        <div className="container pt-30 pb-30">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                    frequently asked questions
                                </h2>
                                <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                    these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                                </p>
                            </div>

                            {faqItems.map((item) => (
                                <div key={item.id} className="container mt-40">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3
                                            className="font-montserrat fs-20 fw-500"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer' }}
                                        >
                                            {item.question}
                                        </h3>
                                        <h3
                                            className="font-montserrat"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                        >
                                            {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                        </h3>
                                    </div>
                                    <hr style={{ borderColor: '#BABABA' }} />
                                    {!item.isCollapsed && (
                                        <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
