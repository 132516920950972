import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import RentalCarsServices from "../../components/rentalCarsServices";
const RentalCarsServicesPage = React.memo((props) => {
  return (
    <>
      <RentalCarsServices />
    </>
  );
});

export default withRouter(RentalCarsServicesPage);
