import React, { useEffect, useState, useContext, } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function Farrari458() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/Ferrari-458-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/Ferrari-458-2.png",
        "../../../../monaco_assets/Ferrari-458-3.png",
        "../../../../monaco_assets/Ferrari-458-4.png",
        "../../../../monaco_assets/Ferrari-458-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a ferrari?",
            answer: "at monaco experiences, ferrari rental prices vary based on the model and rental duration. contact us for specific pricing details.",
            isCollapsed: true
        },
        {
            id: 1,
            question: "why choose a ferrari in red color?",
            answer: "a red ferrari stands out for its iconic and vibrant hue, symbolizing power and passion. it’s the quintessential choice for making a statement.",
            isCollapsed: true
        },
        {
            id: 2,
            question: "what are the requirements to hire a ferrari?",
            answer: "to rent a ferrari, you must be at least 25 years old, possess a valid driver’s license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true
        },
        {
            id: 3,
            question: "how long can i rent a ferrari for?",
            answer: "ferrari rentals at monaco experiences are flexible, ranging from a few hours to several days. custom rental periods can be arranged to suit your needs.",
            isCollapsed: true
        },
        {
            id: 4,
            question: "what happens if the ferrari is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you  be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true
        },
        {
            id: 5,
            question: "where can i find ferrari rentals near me?",
            answer: "monaco experiences provides ferrari rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Ferrari 458 rental | Monaco Experiences Exotic car rental 
                </title>
                <meta name="description" content="Contact us for a customised quotation fot Ferrari rentals by Monaco Experiences for your wedding or any special occasion." />
                <meta name="keywords" content="Ferrari Rental near me, Ferrari rentals" />
                <link rel="canonical" href="https://monacoexperiences.com/farrari-458-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo8.png" alt="Image" style={{ height: '50px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>FERRARI 458 </span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                4.5l v8  |  562 horsepower  |  actual msrp : $ 279,900
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            why ferrari rental stands out?
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            at <span class="fw-700">monaco experiences</span>, we believe that nothing exudes luxury and power quite like a ferrari in red color. renting a red ferrari is more than just driving a car; it's an experience that commands attention and admiration. our ferrari rentals offer a unique combination of style, speed, and <span class="fw-700">sophistication</span> that few other cars can match.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            a red ferrari rental is perfect for those looking to make a bold statement, whether it's for a wedding, a photoshoot, or a special event. the iconic ferrari red color symbolizes <span class="fw-700">passion</span> and <span class="fw-700">performance</span>, making it an ideal choice for thrill-seekers and <span class="fw-700">luxury enthusiasts alike</span>. at monaco experiences, we offer the latest models we have for rent, ensuring you enjoy the best of ferrari's engineering marvels. So, <b>why settle for less</b> when you can rent a ferrari and experience the ultimate in automotive excellence? discover how much a ferrari costs and how much it costs to rent a ferrari today <span class="fw-700">here</span>.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            experience the unparalleled joy of driving a red ferrari and discover why this iconic car remains the dream of many! 🎯
                        </p>
                    </div>
                </div>
            </div>



            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
