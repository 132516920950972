import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ExoticFleet from "../../components/exoticFleet";
const ExoticFleetPage = React.memo((props) => {
  return (
    <>
      <ExoticFleet />
    </>
  );
});

export default withRouter(ExoticFleetPage);
