import React, { useEffect, useState, useContext, } from "react";
import Video from '../../assets/video/video.mp4';
import axios from "axios";

export default function Gallery() {

    const [showType, setShowType] = useState('');
    const [mainImage, setMainImage] = useState('')
    const [mainVideo, setMainVideo] = useState('../../../../monaco_assets/video/2.mp4')


    const [vehicles, setVehicles] = useState([])
    const [vehiclesVideo, setVehiclesVideo] = useState([])

    const [selectedVehicle, setSelectedVehicle] = useState({})
    const [selectedMainImage, setSelectedMainImage] = useState('')

    console.log('mainVideo', mainVideo)

    const handleChange = (item) => {
        setShowType(item)
        window.scrollTo(0, 0)
    }

    const handleChangeVideo = (item) => {
        console.log('mainVideo1', item)
        setMainVideo(item)
        window.scrollTo(0, 0)
    }
    const handleChangeImage = (item) => {
        setMainImage(item)
        setSelectedMainImage('')
    }

    const VD = {}


    useEffect(() => {
        // fetch cars and images from http://api.monacoexperiences.com/api/v1/cars/
        axios.get('https://api.monacoexperiences.com/api/v1/images?type=1')
            .then((response) => {
                setVehicles(response.data.results)
                response.data.results.map((item) => {
                    VD[item.id] = {
                        "name": item.make + ' ' + item.model,
                        "mainImage": item.main_image,
                        "images": item.images
                    }
                })
                setSelectedMainImage(response.data.results[0].file)
            })
            .catch((error) => {
                console.log('error', error)
            })


            axios.get('https://api.monacoexperiences.com/api/v1/images?type=2')
            .then((response) => {
                setVehiclesVideo(response.data.results)
                response.data.results.map((item) => {
                    VD[item.id] = {
                        "name": item.make + ' ' + item.model,
                        "mainImage": item.main_image,
                        "images": item.images
                    }
                })
                setSelectedMainImage(response.data.results[0].file)
            })
            .catch((error) => {
                console.log('error', error)
            })
    }, [])



    return (
        <>
            <div style={{ background: '#000000', marginTop: "50px" }} class="blog-page-white-bg blog-v3">
                <div class="container">
                    <div class="feature-blog-three font-montserrat">
                        <div style={{ cursor: "pointer" }} class="post-meta" data-aos="fade-up" data-aos-duration="1200">
                            {/* <img src='../../../../monaco_assets/gallery-main.png' /> */}
                            {showType === '' ?

                                <>
                                    <img src={mainImage === '' ? selectedMainImage : mainImage} alt={selectedVehicle.make} width='100%' />

                                </> :

                                <video autoPlay src={mainVideo} style={{ width: '100%' }} type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>

                            }
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div class="input-field" style={{ padding: '8px' }}>
                                <button style={{ fontWeight: "500", background: '#B88B2B' }} type="submit" class=" template-btn" onClick={() => handleChange('')}>photos</button>
                            </div>
                            <div class="input-field" style={{ padding: '8px', width: '200px' }}>
                                <button style={{ fontWeight: "500", background: '#B88B2B' }} type="submit" class=" template-btn" onClick={() => handleChange('video')}>videos</button>
                            </div>
                        </div>

                        {showType === '' ?
                            <div class='row' style={{ overflowY: 'scroll', height: '300px' }}>
                                {vehicles && vehicles.map(item =>
                                    <div class='col-lg-3 col-6 col-md-6 mt-30' style={{ cursor: "pointer" }}>
                                        {item.type === 1 ?
                                            <img src={item.file} onClick={() => handleChangeImage(item.file)} style={{ width: '250px' }} />
                                            : null}
                                    </div>
                                )}
                                <div class='col-lg-3 col-6 col-md-6 mt-30' style={{ cursor: "pointer" }}>
                                </div>
                            </div>
                            :
                            <div class='row' >
                                {vehiclesVideo && vehiclesVideo.map(item =>
                                    <div class='col-lg-3 col-6 col-md-6 mt-30' style={{ cursor: "pointer" }}>
                                        {item.type === 2 ?
                                            <video loop autoPlay muted onClick={() => handleChangeVideo(item.file)}>
                                                <source src={item.file} type="video/mp4" style={{ width: '250px' }} />
                                                Your browser does not support the video tag.
                                            </video>
                                            : null
                                        }
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
