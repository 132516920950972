import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";

export default function Location(props) {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of luxury cars are available for rent in boston?",
            answer: "we offer a wide range of luxury cars, including lamborghini huracan, ferrari f8 spyder, rolls royce dawn, bentley gtc, mclaren and more to check our full exotic cars fleet click here",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why moncao experiences is the best exotic car rental services in boston?",
            answer: "at monaco experiences, we offer top-notch exotic car rental services in boston, featuring luxury brands like lamborghini, ferrari, rolls royce, and more. our exceptional customer service and premium car selection makes the best choice for your exotic car rental needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "do you provide baraat packages in boston?",
            answer: "yes, we provide specialized baraat packages in boston. our unique baraat experience mobile van is equipped with a tv, smoke gun, dj, lights, and a beer/wine tank, perfect for indian weddings. contact us to customize your baraat package.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i get a luxury car rental for a wedding in boston?",
            answer: "yes, we offer luxury car rentals for weddings in boston to make your special day extraordinary. choose from our wide selection of luxury cars to add a touch of elegance to your wedding. check our full exotic cars fleet here",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Exotic,Luxury and Vintage Car Hire in Boston - Monaco Experiences 
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-boston" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/boston-location.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental boston</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>
                </div>

                <div style={{ background: '#111' }}>
                    <div class="container pt-30 pb-30">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-30 fw-800 text-center" style={{ color: '#fff' }}>
                                    discover boston in style with monaco experience luxury car rentals
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                experience the ultimate in luxury and performance with monaco experiences, the <span className="fw-700">top choice for exotic car rentals</span> in boston. our unrivaled selection of vehicles ensures <span className="fw-700">you stand out in style</span>, whether you're attending a special event or exploring the city. we pride ourselves on providing an <span className="fw-700">exceptional fleet</span> that includes top-tier models like the lamborghini huracan, ferrari f8 spyder, and rolls royce dawn.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                our boston exotic car rental service is designed to cater to your every need, offering a seamless and unforgettable experience. for those who appreciate classic charm, our vintage car rental selection features timeless icons like the porsche speedster and excalibur. <span className="fw-700">elevate any occasion</span> with our luxury car rental options, perfect for weddings, photoshoots, music videos, and more.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                choose monaco experiences for your luxury car rental in boston and enjoy <span className="fw-700">unmatched service</span>, quality, and elegance. book your dream car today and discover why we are the preferred choice for exotic car rental in boston. whether you need a sleek <span className="fw-700">sports car</span> or a charming <span className="fw-700">vintage ride</span>, we have the perfect vehicle to make your experience <span className="fw-700">extraordinary</span>.
                            </p>
                        </div>
                    </div>
                </div>


                <LogoScrolling />

                <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                    <div class="container section-heading text-center">
                        <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                            available exotic cars in boston
                        </h2>
                        <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                            classy and luxury rental cars in <span style={{ fontWeight: 700 }}>boston</span> : discover our unique <span style={{ fontWeight: 700 }}>showroom experience.</span>
                        </p>
                    </div>
                    <ExoticRental16Car extraText={"RENTAL IN BOSTON"} />
                </div>


                <DriveTheDream extraText={"in boston"} />

                <StoriesFromCustomer />
                <div style={{ background: '#111' }}>
                    <div style={{ background: '#111' }}>
                        <div className="container pt-30 pb-30">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                    frequently asked questions
                                </h2>
                                <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                    these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                                </p>
                            </div>

                            {faqItems.map((item) => (
                                <div key={item.id} className="container mt-40">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3
                                            className="font-montserrat fs-20 fw-500"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer' }}
                                        >
                                            {item.question}
                                        </h3>
                                        <h3
                                            className="font-montserrat"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                        >
                                            {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                        </h3>
                                    </div>
                                    <hr style={{ borderColor: '#BABABA' }} />
                                    {!item.isCollapsed && (
                                        <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
