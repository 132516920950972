import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function PorscheSpeedster1956() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/porsche-speedster-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/porsche-speedster-2.png",
        "../../../../monaco_assets/porsche-speedster-3.png",
        "../../../../monaco_assets/porsche-speedster-4.png",
        "../../../../monaco_assets/porsche-speedster-5.png",
    ])


    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a vintage car?",
            answer: "at monaco experiences, vintage car rental prices vary based on the model and rental duration. contact us for specific pricing details.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what are the requirements to rent a vintage cars?",
            answer: "to rent a vintage car, you must be at least 25 years old, possess a valid driver's license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "are there any mileage restrictions when hiring the porsche speedster 1956 ?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can the porsche speedster 1956 be delivered to my location?",
            answer: "yes, we offer a delivery and pick-up service for all the cars to your specified address within our service areas for an additional fee.",
            isCollapsed: true,
        },
        {
            id: 5,
            question: "what happens if the porsche speedster is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you may be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true,
        },
        {
            id: 6,
            question: "can i rent a vintage car for wedding?",
            answer: "yes, you can rent a vintage car for your wedding with monaco experiences. our stunning vintage cars add timeless elegance and sophistication to your special day. also, check out our excalibur.",
            isCollapsed: true,
        },
        {
            id: 7,
            question: "where can i find the vintage car rental near me?",
            answer: "monaco experiences provides vintage car rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Vintage Porsche car rental in NJ,NYC | Monaco Experiences
                </title>
                <meta name="description" content=" Porsche vintage car for Hire by Monaco Experiences for weddings or special occasions. Timeless design and luxury. Contact us for a customised quote." />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/porsche-speedster-1956-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo9.png" alt="Image" style={{ height: '50px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>PORSCHE SPEEDSTER</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                show stopper  |  610 horsepower  |  actual msrp : $ 459,900
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            gear up for the vintage - classic car rentals
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            step back in time with monaco experiences and relish the charm of yesteryear with our exclusive vintage car rentals. whether you're a classic car enthusiast or planning a wedding with a touch of elegance, our collection is tailored to make your dreams come true. imagine cruising in a vintage porsche speedster 1956, feeling the blend of timeless design and modern innovation with its tesla-powered electric engine.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            our vintage & classic car rental offers a unique and sophisticated touch, ensuring your big day is memorable and picturesque. choose from our exquisite lineup, including the iconic porsche speedster 1956, and make a grand entrance that echoes style and luxury.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            monaco experiences specializes in providing old wedding cars that not only enhance your event but also create lasting memories. our commitment to quality and customer satisfaction ensures you have a seamless experience from start to finish. dive into nostalgia and make your special moments unforgettable with our classic car rentals. discover the perfect blend of history and modern luxury with monaco experiences, your premier destination for vintage car rentals.
                        </p>

                        <p class="font-montserrat fw-600 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            for more options, check out our stunning excalibur as well 🤘
                        </p>
                    </div>
                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury cars rental  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
