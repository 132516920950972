import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validationBusiness';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import axios from 'axios';
import moment from 'moment';
import StoriesFromCustomer from './home/storiesFromCustomer';

const init = {
  subscriber_fname: '',
  subscriber_lname: '',
  subscriber_email: '',
  subscriber_phone: '',
  no_of_guest: '',
  subscriber_cars: '',
  subscriber_venue: '',
  check_in: '',
  check_out: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );


// const validateGmail = (email) =>
// String(email)
//   .match(
//     'gmail'
//   );



const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function HouseOfMonacoContact() {
  const [state, setState] = useState(init);
  const [number, setNumber] = useState([])
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);

  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    setState({
      ...state,
      'check_in': date,
    });
  };

  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
    setState({
      ...state,
      'check_out': date,
    });
  };

  const handleClose = () => {
    setErrors(false)
    setState(init);
    setOpen(false)
  }

  // if(state.subscriber_email.match("gmail")){
  //   errors.subscriber_email = 'Please enter official email';
  // }

  const handleChangeNumberLeadInput = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };


  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));
    if (
      state.subscriber_fname !== '' &&
      state.subscriber_lname !== '' &&
      state.subscriber_email !== '' &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.no_of_guest !== '' &&
      state.check_in !== '' &&
      state.check_out !== ''
    ) {
      const checkedInDate = moment(state.check_in).format('YYYY-MM-DD');
      const checkedOutDate = moment(state.check_out).format('YYYY-MM-DD');

      const postData = {
        first_name: state.subscriber_fname,
        last_name: state.subscriber_lname,
        email: state.subscriber_email,
        phone: state.subscriber_phone,
        number_of_guests: state.no_of_guest,
        check_in: checkedInDate,
        check_out: checkedOutDate,
      };
      console.log('Create Subscriber Detail', postData)
      axios.post('https://api.monacoexperiences.com/api/v1/houseinquiries/', postData)
      setState(init);
      setOpen(true);
      setCheckOutDate('');
      setCheckInDate('');
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <Snackbar open={open} onClose={handleClose} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Form Successfully Submitted!
          </Alert>
        </Snackbar>
        <h4 id='enquiry-form-monaco' style={{ lineHeight: "1.3em", color: '#fff', textAlign: 'center' }} class="pb-20 font-montserrat fs-40 fw-600">connect with our team</h4>

        <div class="consultation-form" style={{ background: '#242424' }}>

          <Grid container xs={12}>
            <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}>name*</label>
              <TextField fullWidth label="FIRST NAME*"
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'
                  },
                }} variant="outlined" type="text" name="subscriber_fname" value={state.subscriber_fname} onChange={handleChange} />
              {errors.subscriber_fname && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_fname}</p>}
            </Grid>

            <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}> &nbsp;</label>
              <TextField fullWidth label="LAST NAME*"
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'
                  },
                }} variant="outlined" type="text" name="subscriber_lname" value={state.subscriber_lname} onChange={handleChange} />
              {errors.subscriber_lname && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_lname}</p>}
            </Grid>


            <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}>No of guests*</label>

              <TextField fullWidth
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'

                  },
                }} variant="outlined" type="text" name="no_of_guest" value={state.no_of_guest} onChange={handleChangeNumberLeadInput} />
              {errors.no_of_guest && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.no_of_guest}</p>}
            </Grid>

            <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}>phone number*</label>

              <TextField fullWidth
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'

                  },
                }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
              {errors.subscriber_phone && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_phone}</p>}
            </Grid>

            <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}>Check In*</label>
              <DatePicker selected={checkInDate} onChange={handleCheckInDateChange} style={{ color: '#000' }} />
              {errors.check_in && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.check_in}</p>}
            </Grid>

            <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}>Check out*</label>
              <DatePicker selected={checkOutDate} onChange={handleCheckOutDateChange} style={{ color: '#000' }} />
              {errors.check_out && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.check_out}</p>}
            </Grid>

            <Grid padding={1} xs={12} sm={12} md={6} lg={6}>
              <label class='font-montserrat' style={{ textTransform: 'lowercase', color: '#fff' }}>email*</label>

              <TextField fullWidth
                size='small'
                inputProps={{
                  style: {
                    height: "20px",
                    background: '#fff'

                  },
                }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
              {errors.subscriber_email && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_email}</p>}
            </Grid>


          </Grid>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 10px 0px 0px', }}>
            <div class="input-field" style={{ padding: '8px', width: '200px' }}>
              <button style={{ fontWeight: "600", background: '#B88B2B', color: '#000', padding: '5px 10px' }} onClick={handleClick} type="submit" class=" template-btn">inquire</button>
            </div>
            <p className="font-montserrat fs-10" style={{ color: '#C3C3C3' }} >
              100% SECURE, NO SPAM!
            </p>
          </div>
        </div>

        <h4 style={{ lineHeight: "1.3em", color: '#D7D7D7', textAlign: 'center' }} class="pt-30 pb-10 font-montserrat fs-40 fw-600">
          need assistance right away?
        </h4>

        <h4 style={{ lineHeight: "1.3em", color: '#D7D7D7', textAlign: 'center' }} class="pb-20 font-montserrat fs-20 fw-400">
          chat with us on whatsapp for immediate support at lightning speed 💫
        </h4>

        <div style={{ padding: '8px' }}>
          <button style={{ fontWeight: "600", background: '#D9D9D9', color: '#000', padding: '5px 10px' }} type="submit" class=" template-btn">
            <a target="_blank" href="https://wa.link/49oo39">
              click here
            </a>
          </button>
        </div>

      </div>

    </>
  );
}
