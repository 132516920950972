import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { localRoute } from "../../routes/localRoutes";

export default function Footer() {
    const history = useHistory();
    const location = useLocation();

    const [serviceDropdown, setServiceDropdown] = useState(false);
    const [locationDropdown, setLocationDropdown] = useState(false);
    const [topBrandsDropdown, setTopBrandsDropdown] = useState(false);
    const [companyDropdown, setCompanyDropdown] = useState(false);


    const handleBackHome = () => {
        history.push(localRoute.home)
        window.scrollTo(0, 0)
    };

    const handleBackMansions = () => {
        history.push(localRoute.mansions)
        window.scrollTo(0, 0)
    };

    const handleLocation = () => {
        history.push(localRoute.location)
        window.scrollTo(0, 0)
    }

    const handleLocationLosAngeles = () => {
        history.push(localRoute.LosAngeles)
        window.scrollTo(0, 0)
    }

    const handleLocationCancun = () => {
        history.push(localRoute.cancun)
        window.scrollTo(0, 0)
    }

    const handleLocationMedellin = () => {
        history.push(localRoute.medellin)
        window.scrollTo(0, 0)
    }

    const handleLocationPhiladelphia = () => {
        history.push(localRoute.philadelphia)
        window.scrollTo(0, 0)
    }

    const handleLocationNewYork = () => {
        history.push(localRoute.newYork)
        window.scrollTo(0, 0)
    }

    const handleLocationNewJersey = () => {
        history.push(localRoute.newJersey)
        window.scrollTo(0, 0)
    }

    const handleLocationPuerto = () => {
        history.push(localRoute.puerto)
        window.scrollTo(0, 0)
    }

    const handleLocationWashington = () => {
        history.push(localRoute.washington)
        window.scrollTo(0, 0)
    }

    const handleServices = () => {
        history.push(localRoute.services)
        window.scrollTo(0, 0)
    }

    const handleExoticCarRentalForMusicVideo = () => {
        history.push(localRoute.exoticCarRentalForMusicVideo)
        window.scrollTo(0, 0)
    }
    const handleExoticCarRentalForPhotoshoot = () => {
        history.push(localRoute.exoticCarRentalForPhotoshoot)
        window.scrollTo(0, 0)
    }
    const handleExoticCarRentalForWedding = () => {
        history.push(localRoute.exoticCarRentalForWedding)
        window.scrollTo(0, 0)
    }
    const handleVintageCarRental = () => {
        history.push(localRoute.vintageCarRental)
        window.scrollTo(0, 0)
    }
    const handleSelfDrivenExoticCarRental = () => {
        history.push(localRoute.selfDrivenExoticCarRental)
        window.scrollTo(0, 0)
    }

    const handleCarsBentley = () => {
        history.push(localRoute.bentleyGTCRental)
        window.scrollTo(0, 0)
    }

    const handleCarsLamborghiniHuracanEvoRental = () => {
        history.push(localRoute.lamborghiniHuracanEvoRental)
        window.scrollTo(0, 0)
    }

    const handleCarsRollsRoyceDawnRental = () => {
        history.push(localRoute.rollsRoyceDawnRental)
        window.scrollTo(0, 0)
    }

    const handleCarsFarrari458Rental = () => {
        history.push(localRoute.cars)
        window.scrollTo(0, 0)
    }

    const handleCarsPorscheSpeedster1956Rental = () => {
        history.push(localRoute.porscheSpeedster1956Rental)
        window.scrollTo(0, 0)
    }

    const handleCarsMclaren720sRental = () => {
        history.push(localRoute.mclaren720sRental)
        window.scrollTo(0, 0)
    }

    const handleBlogs = () => {
        history.push(localRoute.blog)
        window.scrollTo(0, 0)
    }

    const handleServiceDropDown = () => {
        setServiceDropdown(prevState => !prevState);
        if (!serviceDropdown) {
            setLocationDropdown(false);
            setTopBrandsDropdown(false);
            setCompanyDropdown(false);
        }
    };

    const handleLocationDropDown = () => {
        setLocationDropdown(prevState => !prevState);
        if (!locationDropdown) {
            setServiceDropdown(false);
            setTopBrandsDropdown(false);
            setCompanyDropdown(false);
        }
    };

    const handleTopBrandsDropDown = () => {
        setTopBrandsDropdown(prevState => !prevState);
        if (!topBrandsDropdown) {
            setServiceDropdown(false);
            setLocationDropdown(false);
            setCompanyDropdown(false);
        }
    };

    const handleCompanyDropDown = () => {
        setCompanyDropdown(prevState => !prevState);
        if (!companyDropdown) {
            setServiceDropdown(false);
            setLocationDropdown(false);
            setTopBrandsDropdown(false);
        }
    };
    const handleExoticFleet = () => {
        history.push(localRoute.exoticFleet)
        window.scrollTo(0, 0)
    };


    const handleWhoWeAre = () => {
        history.push(localRoute.whoWeAre)
        window.scrollTo(0, 0)
    };

    useEffect(() => {
        if (location.hash === '#open-enquiry') {
            const element = document.querySelector("#enquiry-form1");
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    const handleOpenEnquiry = () => {
        history.push('/#open-enquiry');
    };

    return (
        <>

            <footer style={{ backgroundColor: '#000000' }} className="theme-footer-two pt-50 md-pt-40">

                <div className="container">
                    <div className="row justify-content-center">
                        <a onClick={handleBackHome}>
                            <img style={{ width: "4em", cursor: 'pointer' }} src="../../../../monaco_assets/monaco-footer.png" alt="monaco-logo" />
                        </a>
                    </div>
                </div>

                <div className="container pt-40 pb-40">
                    <div className="font-montserrat fs-20 fw-200" style={{ color: '#FFFFFF', textAlign: 'center', lineHeight: 1.5 }}>
                        established in 2018, monaco experiences has swiftly emerged as the ultimate destination for luxury car rentals in the united states. our journey began with a passion for delivering unparalleled experiences, and since then, we've proudly served a prestigious clientele including celebrities and artists. with a reputation built on trust and excellence, monaco experiences invites you to elevate your driving experience with our elite fleet of supercars
                    </div>
                </div>

                {/* show on desktop */}
                <div className="top-footer mt-20 show-on-desktop-view">
                    <div className="container">
                        <div className="row justify-content-between">

                            <div className="font-montserrat fw-500 col-lg-4 col-md-3 col-sm-12 footer-list" >
                                <h5 className="footer-title font-montserrat fw-600">our service coverage</h5>
                                <ul>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationNewJersey}>exotic car rental new jersey</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationNewYork}>exotic car rental new york</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationPhiladelphia}>exotic car rental philadelphia</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocation}>exotic car rental boston</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationWashington}>exotic car rental washington dc</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationLosAngeles}>exotic car rental los angeles</a></li>
                                    {/* <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationPuerto}>exotic car rental puerto rico</a></li> */}
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationCancun}>exotic car rental cancun</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationMedellin}>car rental medellin</a></li>
                                </ul>
                            </div>

                            <div className="font-montserrat fw-500 col-lg-3 col-md-3 col-sm-12 footer-list" >
                                <h5 className="footer-title font-montserrat fw-600">services</h5>
                                <ul>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleExoticCarRentalForWedding}>exotic car rental for wedding</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleExoticCarRentalForPhotoshoot}>exotic car rental for photoshoot </a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleExoticCarRentalForMusicVideo}>exotic car rental for music video</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleSelfDrivenExoticCarRental}>exotic self driven rental car</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleServices}>barat experience van</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleVintageCarRental}>vintage car rental</a></li>
                                </ul>
                            </div>

                            <div className="font-montserrat fw-500 col-lg-2 col-md-3 col-sm-12 footer-list" >
                                <h5 className="footer-title font-montserrat fw-600">top brands</h5>
                                <ul>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsBentley}>benltley</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsRollsRoyceDawnRental}>rolls royce</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsLamborghiniHuracanEvoRental}>lamborghini</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsFarrari458Rental}>ferrari</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsPorscheSpeedster1956Rental}>porsche</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsMclaren720sRental}>mclaren</a></li>
                                </ul>
                            </div>


                            <div className="font-montserrat fw-500 col-lg-3 col-md-3 col-sm-12 address-list">
                                <h5 className="footer-title font-montserrat fw-600 " style={{ textAlign: "left" }} >company</h5>

                                <ul className="mt-10 info">
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleBlogs}>blogs & insights</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleWhoWeAre}>who we are?</a></li>
                                    <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleOpenEnquiry}>contact us</a></li>

                                    <li><a href="tel:+18623659250" style={{ color: '#fff' }}>+1.862.365.9250</a></li>
                                    <li>
                                        <a target="_blank" href="mailto:info@monacoexperiences.com" style={{ color: '#fff' }}>info@monacoexperiences.com</a>
                                    </li>

                                </ul>
                                <ul className="d-flex">
                                    <li><a target="_blank" href="https://instagram.com/monacoexperiences?igshid=NTc4MTIwNjQ2YQ==" style={{ color: '#fff', marginRight: '15px', fontSize: '20px' }}><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a target="_blank" href="https://www.facebook.com/monacoexperiences/?mibextid=LQQJ4d" style={{ color: '#fff', marginRight: '15px', fontSize: '20px' }}><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a target="_blank" href="https://wa.link/49oo39" style={{ color: '#fff', marginRight: '15px', fontSize: '20px' }}><i className="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='container show-on-desktop-view'>
                    <div className="bottom-footer-content">
                        <div style={{ borderTop: "1.5px solid #B88B2B", justifyContent: 'space-between' }} className="d-flex align-items-center" >
                            <p style={{ color: '#B88B2B', fontWeight: '700', fontSize: '10px' }} className="font-montserrat pt-10">
                                copyrights 2018 - 2024 monaco experiences. all rights reserved.
                            </p>
                            <p style={{ color: '#B88B2B', fontWeight: '700', fontSize: '10px' }} className="font-montserrat pt-10">
                                designed and developed by goat infotech
                            </p>
                        </div>
                    </div>
                </div>

                {/* show on mobile */}

                <div className='top-footer container show-on-mobile-view mb-20'>

                    <div class="font-montserrat d-lg-flex justify-content-between align-items-center">
                        <ul class="fw-500 font-montserrat navbar-nav" style={{ alignItems: 'flex-start' }}>

                            <ul className="nav-item position-static active dropdown" style={{ width: '100%' }}>
                                <a style={{ margin: 0, padding: '5px 0px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="nav-link" onClick={handleServiceDropDown}>
                                    <p>
                                        OUR SERVICES
                                    </p>
                                    <img style={{ fontSize: '15px' }} src="../../../../monaco_assets/down-arrow.svg" />
                                </a>

                                {serviceDropdown == true &&
                                    <div className='fs-14' style={{ color: '#B88B2B', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleExoticCarRentalForWedding}>exotic car rental for wedding</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleExoticCarRentalForPhotoshoot}>exotic car rental for photoshoot </a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleExoticCarRentalForMusicVideo}>exotic car rental for music video</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleSelfDrivenExoticCarRental}>exotic self driven rental car</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleServices}>barat experience van</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleVintageCarRental}>vintage car rental</a></li>
                                    </div>
                                }
                            </ul>


                            <ul className="nav-item position-static active dropdown" style={{ width: '100%' }}>
                                <a style={{ margin: 0, padding: '5px 0px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="nav-link" onClick={handleLocationDropDown}>
                                    <p>
                                        OUR SERVICE COVERAGE
                                    </p>
                                    <img style={{ fontSize: '15px' }} src="../../../../monaco_assets/down-arrow.svg" />
                                </a>

                                {locationDropdown == true &&
                                    <div className='fs-14' style={{ color: '#B88B2B', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationNewJersey}>exotic car rental new jersey</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationNewYork}>exotic car rental new york</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationPhiladelphia}>exotic car rental philadelphia</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocation}>exotic car rental boston</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationWashington}>exotic car rental washington dc</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationLosAngeles}>exotic car rental los angeles</a></li>
                                        {/* <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationPuerto}>exotic car rental puerto rico</a></li> */}
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationCancun}>exotic car rental cancun</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleLocationMedellin}>car rental medellin</a></li>
                                    </div>
                                }
                            </ul>


                            <ul className="nav-item position-static active dropdown" style={{ width: '100%' }}>
                                <a style={{ margin: 0, padding: '5px 0px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="nav-link" onClick={handleTopBrandsDropDown}>
                                    <p>
                                        TOP BRANDS
                                    </p>
                                    <img style={{ fontSize: '15px' }} src="../../../../monaco_assets/down-arrow.svg" />
                                </a>

                                {topBrandsDropdown == true &&
                                    <div className='fs-14' style={{ color: '#B88B2B', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsBentley}>benltley</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsRollsRoyceDawnRental}>rolls royce</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsLamborghiniHuracanEvoRental}>lamborghini</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsFarrari458Rental}>ferrari</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsPorscheSpeedster1956Rental}>porsche</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleCarsMclaren720sRental}>mclaren</a></li>
                                    </div>
                                }
                            </ul>

                            <ul className="nav-item position-static active dropdown" style={{ width: '100%' }}>
                                <a style={{ margin: 0, padding: '5px 0px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="nav-link" onClick={handleCompanyDropDown}>
                                    <p>
                                        COMPANY
                                    </p>
                                    <img style={{ fontSize: '15px' }} src="../../../../monaco_assets/down-arrow.svg" />
                                </a>

                                {companyDropdown == true &&
                                    <div className='fs-14' style={{ color: '#B88B2B', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <li><a className='font-montserrat' style={{ cursor: "pointer", color: '#fff' }} onClick={handleBlogs}>blogs & insights</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleWhoWeAre}>who we are?</a></li>
                                        <li><a className='font-montserrat mt-10' style={{ cursor: "pointer", color: '#fff' }} onClick={handleOpenEnquiry}>contact us</a></li>

                                        <li><a className='font-montserrat mt-10' href="tel:+18623659250" style={{ color: '#fff' }}>+1.862.365.9250</a></li>
                                        <li>
                                            <a className='font-montserrat mt-10' target="_blank" href="mailto:info@monacoexperiences.com" style={{ color: '#fff' }}>info@monacoexperiences.com</a>
                                        </li>
                                    </div>
                                }
                            </ul>

                        </ul>

                    </div>

                    <ul class="d-flex mt-20 mb-20" style={{ justifyContent: 'center', gap: '10px' }}>
                        <a target="_blank" href="https://instagram.com/monacoexperiences?igshid=NTc4MTIwNjQ2YQ==" style={{ color: '#000', background: '#fff', borderRadius: '50%', fontSize: '25px', height: 35, width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        <a target="_blank" href="https://www.facebook.com/monacoexperiences/?mibextid=LQQJ4d" style={{ color: '#000', background: '#fff', borderRadius: '50%', fontSize: '22px', height: 35, width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        <a target="_blank" href="https://wa.link/49oo39" style={{ color: '#000', background: '#fff', borderRadius: '50%', fontSize: '25px', height: 35, width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                    </ul>


                </div>
                <div className='show-on-mobile-view'>
                    <div className="bottom-footer-content">
                        <div style={{ borderTop: "1.5px solid #B88B2B", textAlign: 'center' }} >
                            <p style={{ color: '#B88B2B', fontWeight: '700', fontSize: '10px' }} className="font-montserrat pt-10">
                                copyrights 2018 - 2024 monaco experiences. all rights reserved.
                            </p>
                            <p style={{ color: '#B88B2B', fontWeight: '700', fontSize: '10px' }} className="font-montserrat pt-5">
                                designed and developed by goat infotech
                            </p>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    )
}
