import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import validation from './validationBusiness';
import { useState, useContext, useEffect } from 'react';
import { padding } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalContext } from '../context/GlobalState';

import { createSubscriber } from '../context/actions/subscriberAction/addSubscriber';
import { Grid, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import axios from 'axios';
import moment from 'moment';

const init = {
  subscriber_fname: '',
  subscriber_email: '',
  subscriber_phone: '',
  subscriber_location: '',
  subscriber_cars: '',
  subscriber_venue: '',
  subscriber_date: ''
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );


// const validateGmail = (email) =>
// String(email)
//   .match(
//     'gmail'
//   );



const style = {
  position: 'absolute',
  top: '15%',
  left: '33%',
  border: '2px solid red',
};

export default function Find_Taskers_Common() {
  const { subscriberState, subscriberDispatch } = useContext(GlobalContext);

  const [state, setState] = useState(init);
  const [store, setStore] = useState([]);
  const [number, setNumber] = useState([])
  const [location, setLocation] = useState('')
  const [singleCar, setSingleCar] = useState('')
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  // const cars = ['AUDI R8 V10', 'BAARAT EXPERIENCE', 'BENTLEY GTC', 'BENTLEY BENTYAGA', 'BMW M4 COMPETITION', 'CORVETTE', 'EXCALIBUR (VINTAGE)', ' FERRARI 488', 'LAMBORGHINI HURACAN', '1956 PORSCHE SPEEDSTER', 'ROLLS ROYCE DAWN', 'MCLAREN 720S']

  // const locations = ['New York', 'New Jersey', 'Philadelphia', 'Washington D.C', 'Boston', 'Los Angeles', 'Cancun', 'Puerto Rico']
  const [locations, setLocations] = useState([]);
  const [cars, setCars] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {

    setSelectedDate(date);
    setState({
      ...state,
      'subscriber_date': date,
    });
  };

  const handleClose = () => {
    setErrors(false)
    setState(init);
    setOpen(false)
  }

  // if(state.subscriber_email.match("gmail")){
  //   errors.subscriber_email = 'Please enter official email';
  // }

  const handleChangeNumberLeadInput = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log('onlyNums', onlyNums)
    if (onlyNums.length <= 10) {
      setNumber(onlyNums)
      setState({
        ...state,
        [event.target.name]: onlyNums,
      });
    }
  }

  const handleChangeLocation = (event) => {
    const onlyLocation = event.target.value;
    console.log('eventevent1', event.target)
    console.log('eventevent2', event.target.name)

    setLocation(onlyLocation)
    setState({
      ...state,
      'subscriber_location': onlyLocation,
    });

  }

  const handleChangeCars = (event) => {
    const onlyCar = event.target.value;
    console.log('eventevent1', event.target.value)
    console.log('eventevent2', event.target.name)

    setSingleCar(onlyCar)
    setState({
      ...state,
      'subscriber_cars': onlyCar,
    });

  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleClick = e => {
    e.preventDefault()
    setErrors(validation(state));
    if (
      state.subscriber_fname !== '' &&
      state.subscriber_email !== '' &&
      state.subscriber_venue !== '' &&
      validateEmail(state.subscriber_email) &&
      state.subscriber_phone !== '' &&
      state.subscriber_phone.length === 10 &&
      state.subscriber_location !== '' &&
      state.subscriber_cars !== '' &&
      state.subscriber_date !== ''
    ) {
      const formattedDate = moment(state.subscriber_date).format('YYYY-MM-DD');

      const postData = {
        first_name: state.subscriber_fname,
        email: state.subscriber_email,
        phone: state.subscriber_phone,
        venue: state.subscriber_venue,
        location: state.subscriber_location,
        cars: [state.subscriber_cars],
        date: formattedDate
      };
      console.log('Create Subscriber Detail', postData)
      // createSubscriber(postData)(subscriberDispatch)
      axios.post('https://api.monacoexperiences.com/api/v1/enquiries/', postData)
      setState(init);
      setOpen(true);
      setSingleCar('');
      setLocation('');
    }
  }


  useEffect(() => {
    // fetch locations from https://api.monacoexperiences.com/api/v1/locations/
    axios.get('https://api.monacoexperiences.com/api/v1/locations/')
      .then(res => {
        setLocations(res.data.results)
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])

  useEffect(() => {
    // fetch cars from https://api.monacoexperiences.com/api/v1/cars/ 
    axios.get('https://api.monacoexperiences.com/api/v1/cars/')
      .then(res => {
        setCars(res.data.results)
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Form Successfully Submitted!
        </Alert>
      </Snackbar>
      <h4 style={{ lineHeight: "1.3em", color: '#D7D7D7', textAlign: 'center' }} class="pb-20 font-montserrat fs-40 fw-600">connect with our team</h4>

      <div class="consultation-form" style={{ background: '#242424' }}>

        <Grid container xs={12}>
          <Grid padding={1} xs={12} sm={12} md={6} lg={6}>
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>name*</label>
            <TextField fullWidth
              size='small'
              inputProps={{
                style: {
                  height: "20px",
                  background: '#fff'
                },
              }} variant="outlined" type="text" name="subscriber_fname" value={state.subscriber_fname} onChange={handleChange} />
            {errors.subscriber_fname && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_fname}</p>}
          </Grid>


          <Grid padding={1} xs={12} sm={12} md={6} lg={6}>
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>email*</label>

            <TextField fullWidth
              size='small'
              inputProps={{
                style: {
                  height: "20px",
                  background: '#fff'

                },
              }} variant="outlined" type="email" name="subscriber_email" value={state.subscriber_email} onChange={handleChange} />
            {errors.subscriber_email && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_email}</p>}
          </Grid>

          <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>phone number*</label>

            <TextField fullWidth
              size='small'
              inputProps={{
                style: {
                  height: "20px",
                  background: '#fff'

                },
              }} variant="outlined" type="text" name="subscriber_phone" value={state.subscriber_phone} onChange={handleChangeNumberLeadInput} />
            {errors.subscriber_phone && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_phone}</p>}
          </Grid>

          <Grid padding={1} xs={6} sm={6} md={3} lg={3}>
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>event date*</label>
            <DatePicker selected={selectedDate} onChange={handleDateChange} style={{ color: '#000' }} />
            {errors.subscriber_date && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_date}</p>}
          </Grid>


          <Grid padding={1} xs={12} sm={12} md={6} lg={6}>
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>location preferance*</label>

            <FormControl sx={{ minWidth: '100%' }} >

              {/* <InputLabel id="demo-select-small-label">Location Preferance</InputLabel> */}
              <Select
                size='small'
                labelId="demo-select-small-l  abel"
                id="demo-select-small"
                value={location}
                style={{
                  height: "37px",
                  background: '#fff'
                }}
                onChange={handleChangeLocation}
              >
                {locations.map(({ id, name }) => (
                  <MenuItem key={id} value={id} style={{ textTransform: 'uppercase' }}>
                    {name}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>

            {errors.subscriber_location && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_location}</p>}
          </Grid>

          <Grid padding={1} xs={12} sm={12} md={6} lg={6}>
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>venue*</label>

            <TextField fullWidth
              size='small'
              inputProps={{
                style: {
                  height: "20px",
                  background: '#fff'

                },
              }} variant="outlined" type="text" name="subscriber_venue" value={state.subscriber_venue} onChange={handleChange} />
            {errors.subscriber_venue && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_venue}</p>}
          </Grid>

          <Grid padding={1} xs={12} sm={12} md={6} lg={6} >
            <label class='font-montserrat mb-5' style={{ textTransform: 'lowercase', color: '#fff' }}>select cars*</label>
            <FormControl sx={{ minWidth: '100%' }} >

              {/* <InputLabel id="demo-select-small-label">Select Cars</InputLabel> */}
              <Select
                size='small'
                labelId="demo-select-small-l  abel"
                id="demo-select-small"
                value={singleCar}
                style={{
                  height: "37px",
                  background: '#fff'
                }}
                onChange={handleChangeCars}
              >
                {cars.map(({ id, make, model }) => (
                  <MenuItem key={id} value={id} style={{ textTransform: 'uppercase' }}>
                    {make} - {model}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>

            {errors.subscriber_cars && <p style={{ color: 'red', textTransform: 'lowercase' }}>{errors.subscriber_cars}</p>}
          </Grid>
        </Grid>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 10px 0px 0px', }}>
          <div class="input-field" style={{ padding: '8px', width: '200px' }}>
            <button style={{ fontWeight: "600", background: '#B88B2B', color: '#000' }} onClick={handleClick} type="submit" class=" template-btn">inquire</button>
          </div>
          <p className="font-montserrat fs-10" style={{ color: '#C3C3C3' }} >
            100% SECURE, NO SPAM!
          </p>
        </div>
      </div>

      <h4 style={{ lineHeight: "1.3em", color: '#D7D7D7', textAlign: 'center' }} class="pt-30 font-montserrat fs-40 fw-600">
        need assistance right away?
      </h4>

      <h4 style={{ display: 'flex', color: '#D7D7D7', textAlign: 'center' }} class="pb-20 font-montserrat fs-20 fw-400">
        connect with us on whatsapp for immediate customer support at lightning speed 💫
      </h4>


      <div className='pb-30'>
        <button style={{ fontWeight: "600", background: '#D9D9D9', color: '#000', padding: '5px 10px' }} type="submit" class="template-btn">
          <a target="_blank" href="https://wa.link/49oo39">
            click here
          </a>
        </button>
      </div>

    </div>
  );
}
