import React from 'react'
import { useEffect, useState, useContext } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import HouseOfMonacoContact from 'components/HouseOfMonacoContact';



export default function ContactHouse() {
    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])


    return (
        <>
            <div id='enquiry-form-monaco' class='pt-20 pb-20'>
                <div class="font-montserrat container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12">
                            <div class="consultation-form-area content-mt-md-30">
                                <HouseOfMonacoContact subscriber_type="Contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
