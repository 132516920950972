import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import WhoWeAre from "../../components/whoWeAre";
const WhoWeArePage = React.memo((props) => {
  return (
    <>
      <WhoWeAre />
    </>
  );
});

export default withRouter(WhoWeArePage);
