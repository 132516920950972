import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { localRoute } from '../../../routes/localRoutes';
import AOS from "aos";
import "aos/dist/aos.css";

const CarItem = ({ title, backgroundImage, description, onclickReadButton }) => (
    <div className="col-lg-4" style={{ height: '450px' }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            marginTop: '15px',
            alignItems: 'stretch',
            padding: '45px 20px',
            lineHeight: '1.5',
            justifyContent: 'space-between',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            borderRadius: 5,
            height: 400,
            marginBottom: '20px'
        }}>
            <div className="font-montserrat fs-20 fw-700 mb-10" style={{ color: '#fff', padding: '10px', lineHeight: '1' }}>{title}</div>
            <div class="fs-18 fw-400" style={{ color: '#fff', padding: '10px 10px 20px' }}>{description}</div>
            <div class="fw-500" style={{ color: '#fff', padding: '10px' }}>
                <a className="font-montserrat fs-20" style={{ textDecoration: 'underline' }} href="#" onClick={onclickReadButton}>read more</a>
            </div>
        </div>
    </div>
);

const DriveTheDream = ({ extraText = '' }) => {
    const history = useHistory();


    const handleServices = () => {
        history.push(localRoute.services)
        window.scrollTo(0, 0)
    }

    const handleExoticCarRentalForMusicVideo = () => {
        history.push(localRoute.exoticCarRentalForMusicVideo)
        window.scrollTo(0, 0)
    }
    const handleExoticCarRentalForPhotoshoot = () => {
        history.push(localRoute.exoticCarRentalForPhotoshoot)
        window.scrollTo(0, 0)
    }
    const handleExoticCarRentalForWedding = () => {
        history.push(localRoute.exoticCarRentalForWedding)
        window.scrollTo(0, 0)
    }
    const handleVintageCarRental = () => {
        history.push(localRoute.vintageCarRental)
        window.scrollTo(0, 0)
    }
    const handleSelfDrivenExoticCarRental = () => {
        history.push(localRoute.selfDrivenExoticCarRental)
        window.scrollTo(0, 0)
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <div className="font-montserrat mt-20" data-aos="fade-up" data-aos-duration="1200">
            <div className="container">
                <div className="row" style={{ justifyContent: 'center' }}>
                    <h2 className="font-montserrat fs-40 fw-600 mb-10" style={{ color: '#D7D7D7', textAlign: 'center' }}>drive the dream: explore our exotic car rental services</h2>
                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#FFFFFF', textAlign: 'center' }}>
                        monaco experiences offers unparalleled luxury car rentals for all special events, ensuring unforgettable moments with our exquisite fleet and top-tier service.
                    </p>
                </div>
            </div>
            <div className="container mt-10">
                <div className="row">
                    <CarItem
                        title={`exotic car rental for wedding ${extraText}`}
                        backgroundImage="../../../../monaco_assets/DriveTheDream-3.png"
                        description="make your wedding unforgettable with our exotic car rentals, adding elegance and glamour to your special day."
                        onclickReadButton={handleExoticCarRentalForWedding}
                    />
                    <CarItem
                        title={`exotic car rental for photoshoot ${extraText}`}
                        backgroundImage="../../../../monaco_assets/DriveTheDream-2.png"
                        description="elevate your social presence with exotic vehicles, ensuring every shot radiates luxury and style"
                        onclickReadButton={handleExoticCarRentalForPhotoshoot}
                    />
                    <CarItem
                        title={`mobile barat experience ${extraText}`}
                        backgroundImage="../../../../monaco_assets/DriveTheDream-1.png"
                        description="unleash the party with our fully-loaded baraat van. featuring a smoke gun, beer tank, DJ, lights, TV, and more"
                        onclickReadButton={handleServices}
                    />


                    <CarItem
                        title={`exotic car rental for music video ${extraText}`}
                        backgroundImage="../../../../monaco_assets/DriveTheDream-4.png"
                        description="enhance your music video with our luxury vehicles, offering stunning visuals  and unforgettable elegance to your viewers."
                        onclickReadButton={handleExoticCarRentalForMusicVideo}
                    />

                    <CarItem
                        title={`exotic self driven rental car ${extraText}`}
                        backgroundImage="../../../../monaco_assets/DriveTheDream-5.png"
                        description="we know it's your dream to drive a luxury car. drive our exotic cars and make your dream come true"
                        onclickReadButton={handleSelfDrivenExoticCarRental}
                    />

                    <CarItem
                        title={`vintage car rental ${extraText}`}
                        backgroundImage="../../../../monaco_assets/DriveTheDream-6.png"
                        description="explore timeless elegance with our vintage car rentals, perfect for weddings and special events!"
                        onclickReadButton={handleVintageCarRental}
                    />
                </div>
            </div>
        </div>
    );
};

export default DriveTheDream;
