import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";


const ExoticExperiencesNationwide = () => {
    const history = useHistory();


    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        
        <div class="container mt-30 mb-30">
            <div class="font-montserrat" data-aos="fade-up" data-aos-duration="1200">
                <div class="section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600 mb-10" style={{ color: '#ffffff' }}>
                        exotic experiences nationwide
                    </h2>
                    <p class="font-montserrat fs-16 fw-300" style={{ color: '#ffffff' }}>
                        experience luxury nationwide with exotic experiences. our premium car rental services are available in select cities and destinations <span style={{ fontWeight: 700 }}>across the country,</span> ensuring you <span style={{ fontWeight: 700 }}>arrive in style wherever you go</span>
                    </p>
                </div>
            </div>
            <div className="container mt-30">
                <div className="row" style={{ justifyContent: 'center' }}>
                    <img src="../../../../monaco_assets/map-monaco.png" alt="Image" />
                </div>
            </div>
        </div>
    );
};

export default ExoticExperiencesNationwide;
