import React, { useEffect } from "react";
import { localRoute } from '../../../routes/localRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from 'react-router-dom';

export default function LuxuryMansion() {

    const history = useHistory()

    const handleBackCars = () => {
        history.push(localRoute.cars)
        window.scrollTo(0, 0)
    };

    const handleOpenMonaco = () => {
        history.push(localRoute.mansions)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        AOS.init({
            duration: 2000
        });
    }, [])

    return (
        <div class="font-montserrat mt-10 mb-30" data-aos="fade-up" data-aos-duration="1200">
            <div className="container">
                <div className="row" style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="../../../../monaco_assets/manssion-logo.png" style={{ height: '100px' }} />
                    <h2 className="font-montserrat fs-40 fw-600 mb-10 mt-10" style={{ color: '#D7D7D7', textAlign: 'center', cursor:'pointer' }} onClick={handleOpenMonaco}>
                        casa mira : luxury mansion by house of monaco
                    </h2>
                </div>
                <p className="font-montserrat fs-16 fw-300" style={{ color: '#FFFFFF', textAlign: 'center' }}>
                    our infinity pool offers sweeping views of <span style={{ fontWeight: 700 }}>medellin</span>. enjoy premium amenities at our <span style={{ fontWeight: 700 }}>ultra-luxury mansion</span>. our premium suv service ensures stylish and <span style={{ fontWeight: 700 }}>comfortable exploration.</span>
                </p>
            </div>

            <div class="container">
                <div class="row">
                    <div class='col-lg-4 mt-30' style={{ justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/luxury-mansion-1.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class='col-lg-4 mt-30' style={{ justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/luxury-mansion-2.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                    <div class='col-lg-4 mt-30' style={{ justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/luxury-mansion-3.png" alt="Image" style={{ borderRadius: '5px' }} />
                    </div>

                </div>
            </div>

        </div>
    )
}