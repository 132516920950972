import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const LogoScrolling = () => {
    const history = useHistory();

    var settings = {
        dots: false,
        fade: false,
        infinite: true,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2000,
        slidesToShow: 7,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    className: "center",
                    centerPadding: "20px",
                    centerMode: true,
                    infinite: true,
                    autoplay: true,
                }
            }
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <div class='col-lg-12 mt-30'>
            <div>
                <h2 class="font-montserrat fs-40 fw-600 mb-30" style={{ textAlign: 'center', color: '#fff' }}>iconic luxury lineup : find your perfect match</h2>
            </div>
            <Slider {...settings}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo1.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo2.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo3.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo4.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo5.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo6.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo7.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/logo8.png" style={{ height: '50px', margin: '5px' }} alt="Image" />
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default LogoScrolling;