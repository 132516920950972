import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import LogoScrolling from "./LogoScrolling";


const WhyChooseUs = () => {
    const history = useHistory();


    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <div class="content-x-spacing mt-30">
            <div class="container font-montserrat" data-aos="fade-up" data-aos-duration="1200">
                <div class="section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff', lineHeight:'1' }}>
                        why choose monaco experiences?
                    </h2>
                    <p class="font-montserrat fs-16 mt-10 fw-300" style={{ color: '#ffffff' }}>
                        <span className="fw-700">top choice</span> in new jersey for weddings, our premier exotic car rental service is trusted by artists and has been <span className="fw-700">featured in</span> a popular netflix event.
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{ justifyContent: 'center' }}>

                    <div class='col-lg-4 mt-20'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', textAlign: 'center' }}>
                            <img src="../../../../monaco_assets/whychoose1.png" alt="Image" style={{ height: '50px' }} />
                            <h2 class='mt-10 fs-20 font-montserrat' style={{ color: '#fff' }}>
                                fexible booking options
                            </h2>
                            <p class="font-montserrat fs-16 fw-300" style={{ color: '#D7D7D7' }}>
                                enjoy personalized bookings, effortless pick-up, drop-off, and optional delivery services for a <span style={{ fontWeight: 700 }}>hassle-free experience</span>.
                            </p>
                        </div>
                    </div>
                    <div class='col-lg-4 mt-20'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', textAlign: 'center' }}>
                            <img src="../../../../monaco_assets/whychoose2.png" alt="Image" style={{ height: '50px' }} />
                            <h2 class='mt-10 fs-20 font-montserrat' style={{ color: '#fff' }}>
                                transparent pricing
                            </h2>
                            <p class="font-montserrat fs-16 fw-300" style={{ color: '#D7D7D7' }}>
                                experience peace of mind with clear, upfront pricing, <span style={{ fontWeight: 700 }}>no hidden fees or surprises</span>.
                            </p>
                        </div>
                    </div>

                    <div class='col-lg-4 mt-20'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', textAlign: 'center' }}>
                            <img src="../../../../monaco_assets/whychoose3.png" alt="Image" style={{ height: '60px' }} />
                            <h2 class='mt-10 fs-20 font-montserrat' style={{ color: '#fff' }}>
                                well maintained vehicles
                            </h2>
                            <p class="font-montserrat fs-16 fw-300" style={{ color: '#D7D7D7' }}>
                                our vehicle are regularly serviced and cleaned to ensure they remain in excellent condition, providing a reliable and <span style={{ fontWeight: 700 }}>safe driving experience</span>.
                            </p>
                        </div>
                    </div>

                    <div class='col-lg-12'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', textAlign: 'center' }}>
                            <img src="../../../../monaco_assets/whychoose4.png" alt="Image" style={{ height: '60px' }} />
                            <h2 class='mt-10 fs-20 font-montserrat' style={{ color: '#fff' }}>
                                extensive luxury collection
                            </h2>
                            <p class="font-montserrat fs-16 fw-300" style={{ color: '#D7D7D7' }}>
                                experience new jersey's best exotic car rentals with our premium fleet. discover luxury and performance <span style={{ fontWeight: 700 }}>like never before</span>.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <LogoScrolling/>
        </div>
    );
};

export default WhyChooseUs;