import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useLocation,
  useHistory
} from "react-router-dom";
import Home from "../pages/home";
import Blog from "../pages/blog/blogMain";
import Header from "../pages/header";
import Footer from "../pages/footer";
import About from "../pages/about";
import Cars from "../pages/cars";
import Gallery from "../pages/gallery";
import Location from "../pages/location";
import Services from "../pages/services";
import Mansions from "../pages/mansions";
import NotFound from "../pages/notFound";
import NotFound500 from "../pages/notFound500";
import { localRoute } from "./localRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import ExoticFleet from "../pages/exoticFleet";
import WhoWeAre from "../pages/whoWeAre";
import LosAngeles from "components/location/LosAngeles";
import Cancun from "components/location/Cancun";
import Philadelphia from "components/location/Philadelphia";
import Newyork from "components/location/Newyork";
import NewJersey from "components/location/NewJersey";
import Puerto from "components/location/Puerto";
import Washington from "components/location/Washington";
import Audi from "components/cars/Audi";
import BentleyBentayga from "components/cars/BentleyBentayga";
import Chevrolet from "components/cars/ChevroletCorvetteStingray";
import BentleyGTC from "components/cars/BentleyContinentalGtc";
import BMWCompetition from "components/cars/BMWM4Competition";
import LamborghiniHuracanEvo from "components/cars/LamborghiniHuracanEvo";
import LamborghiniHuracan from "components/cars/LamborghiniHuracan";
import ExcaliburPheaton1972 from "components/cars/ExcaliburPheaton1972";
import Farrari458 from "components/cars/Farrari458";
import Mclaren720s from "components/cars/Mclaren720s";
import RollsRoyceDawn from "components/cars/RollsRoyceDawn";
import PorscheSpeedster1956 from "components/cars/PorscheSpeedster1956";
import ExoticCarRentalForMusicVideo from "components/rentalCarsServices/ExoticCarRentalForMusicVideo";
import ExoticCarRentalForPhotoshoot from "components/rentalCarsServices/ExoticCarRentalForPhotoshoot";
import ExoticCarRentalForWedding from "components/rentalCarsServices/ExoticCarRentalForWedding";
import VintageCarRental from "components/rentalCarsServices/VintageCarRental";
import SelfDrivenExoticCarRental from "components/rentalCarsServices/SelfDrivenExoticCarRental";
import Medellin from "components/location/Medellin";
import BlogDetails1 from "pages/blog/blogDetails1/BlogDetails1";
import RollsRoyceWraith from "components/cars/RollsRoyceWraith";
import AstonMartin from "components/cars/AstonMartin";


function RouterComponent(props) {
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, [])
  let pageTitlePath = history.location && history.location.pathname.split('/')
  console.log("pageTitlePath", pageTitlePath)

  return (
    <React.Fragment>
      <Router>
        <Header />
        <Switch>
          <Route exact path={localRoute.home} component={Home} />
          <Route path={localRoute.about} component={About} />
          <Route path={localRoute.gallery} component={Gallery} />
          <Route path={localRoute.mansions} component={Mansions} />

          <Route path={localRoute.cars} component={Cars} />
          <Route path={localRoute.audiRental} component={Audi} />
          <Route path={localRoute.bentleyBentaygaRental} component={BentleyBentayga} />
          <Route path={localRoute.bentleyGTCRental} component={BentleyGTC} />
          <Route path={localRoute.bmwCompetitionRental} component={BMWCompetition} />
          <Route path={localRoute.chevroletRental} component={Chevrolet} />
          <Route path={localRoute.lamborghiniHuracanEvoRental} component={LamborghiniHuracanEvo} />
          <Route path={localRoute.lamborghiniHuracanRental} component={LamborghiniHuracan} />
          <Route path={localRoute.excaliburPheaton1972Rental} component={ExcaliburPheaton1972} />
          <Route path={localRoute.farrari458Rental} component={Farrari458} />
          <Route path={localRoute.porscheSpeedster1956Rental} component={PorscheSpeedster1956} />
          <Route path={localRoute.rollsRoyceDawnRental} component={RollsRoyceDawn} />
          <Route path={localRoute.mclaren720sRental} component={Mclaren720s} />

          <Route path={localRoute.rollsRoyceWraithRental} component={RollsRoyceWraith} />
          <Route path={localRoute.astonMartinRental} component={AstonMartin} />

          <Route path={localRoute.location} component={Location} />
          <Route path={localRoute.LosAngeles} component={LosAngeles} />
          <Route path={localRoute.cancun} component={Cancun} />
          <Route path={localRoute.philadelphia} component={Philadelphia} />
          <Route path={localRoute.newYork} component={Newyork} />
          <Route path={localRoute.newJersey} component={NewJersey} />
          <Route path={localRoute.puerto} component={Puerto} />
          <Route path={localRoute.washington} component={Washington} />
          <Route path={localRoute.medellin} component={Medellin} />

          <Route path={localRoute.services} component={Services} />
          <Route path={localRoute.exoticCarRentalForMusicVideo} component={ExoticCarRentalForMusicVideo} />
          <Route path={localRoute.exoticCarRentalForPhotoshoot} component={ExoticCarRentalForPhotoshoot} />
          <Route path={localRoute.exoticCarRentalForWedding} component={ExoticCarRentalForWedding} />
          <Route path={localRoute.vintageCarRental} component={VintageCarRental} />
          <Route path={localRoute.selfDrivenExoticCarRental} component={SelfDrivenExoticCarRental} />

          <Route path={localRoute.exoticFleet} component={ExoticFleet} />
          <Route path={localRoute.whoWeAre} component={WhoWeAre} />
          <Route path={localRoute.blog} component={Blog} />
          <Route path={`${localRoute.blogDetail}/:blog_slug`} component={BlogDetails1} />

          <Route
            path={`${localRoute.notFound}`}
            component={NotFound}
          />
          <Route
            path={`${localRoute.notFound500}`}
            component={NotFound500}
          />
        </Switch>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default withRouter(RouterComponent);
