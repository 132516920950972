import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from 'react-router-dom';

const AskQuestion = () => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({ duration: 2000 });
    }, []);

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what sets monaco experiences apart from other exotic car rental companies?",
            answer: "at monaco experiences, our dream is to make everyone's dream of driving an exotic car come true. we are dedicated to providing exceptional customer service and go above and beyond to ensure your experience is unforgettable.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "can i find monaco experiences exotic car rental services near me?",
            answer: "yes, monaco experiences provides exotic car rental services in los angeles, new york, boston, philadelphia,washington dc, puerto rico, cancun, new jersey, and medellin. we ensure our luxury car rental services areeasily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true,
        },
        {
            id: 2,
            question: "how do i make a booking with monaco experiences?",
            answer: "booking with monaco experiences is easy and hassle-free. simply browse our fleet of luxury and exotic cars, fill out our short inquiry form, or contact us via whatsapp. our representative will get back to you as soon as possible to finalize your booking.",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    return (
        <div className="">
            <div className="container pt-30 pb-30">
                <div style={{ textAlign: 'center' }}>
                    <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                        frequently asked questions
                    </h2>
                    <p className="font-montserrat mt-10 fw-300" style={{ color: '#FFF' }}>
                        these are the questions we've received from our real-time customers about renting an exotic car from Monaco Experiences.
                    </p>
                </div>

                {faqItems.map((item) => (
                    <div key={item.id} className="container mt-40">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3
                                className="font-montserrat fs-20 fw-500"
                                onClick={() => toggleCollapse(item.id)}
                                style={{ color: '#FFFFFF', cursor: 'pointer' }}
                            >
                                {item.question}
                            </h3>
                            <h3
                                className="font-montserrat"
                                onClick={() => toggleCollapse(item.id)}
                                style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                            >
                                {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                            </h3>
                        </div>
                        <hr style={{ borderColor: '#BABABA' }} />
                        {!item.isCollapsed && (
                            <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                {item.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AskQuestion;
