import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";

export default function Cancun(props) {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of luxury cars are available for rent in cancun?",
            answer: "we offer a wide range of luxury cars, including lamborghini huracan, ferrari f8 spyder, rolls royce dawn, bentley gtc, mclaren and more to check our full exotic cars fleet click here",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "why moncao experiences is the best exotic car rental services in cancun?",
            answer: "at monaco experiences, we offer top-notch exotic car rental services in cancun, featuring luxury brands like lamborghini, ferrari, rolls royce, and more. our exceptional customer service and premium car selection makes the best choice for your exotic car rental needs.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "do you provide baraat packages in cancun?",
            answer: "yes, we provide specialized baraat packages in cancun. our unique baraat experience mobile van is equipped with a tv, smoke gun, dj, lights, and a beer/wine tank, perfect for indian weddings. contact us to customize your baraat package.",
            isCollapsed: true,
        },
        {
            id: 4,
            question: "can i get a luxury car rental for a wedding in cancun?",
            answer: "yes, we offer luxury car rentals for weddings in cancun to make your special day extraordinary. choose from our wide selection of luxury cars to add a touch of elegance to your wedding. check our full exotic cars fleet here",
            isCollapsed: true,
        },
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Cancun Exotic, Luxury & Vintage Car Rentals | Monaco Experiences 
                </title>
                <meta name="description" content="Get the best quote for exotic car rentals in Cancun. Choose from Ferrari, Lamborghini, and McLaren for weddings, events, and special occasions. Experience luxury in paradise!" />
                <meta name="keywords" content="weding car rental" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-cancun" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/cancun.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental cancun</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>
                </div>

                <div style={{ background: '#111' }}>

                    <div class="container pt-30 pb-30">
                        <div class="row justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                            <div class="col-lg-12 mb-10">
                                <h2 class="font-montserrat fs-30 fw-800 text-center" style={{ color: '#fff' }}>
                                    fuel your cancun fairytale: exotic & luxury car rentals
                                </h2>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                indulge in the ultimate driving experience with monaco experiences, your premier choice for exotic car rentals in cancun, mexico. explore the vibrant city and stunning beaches in unparalleled style and comfort. our diverse fleet boasts everything from the thrilling roar of a lamborghini to the <span className="fw-700">timeless elegance of a rolls royce</span>, catering to your every desire.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                turn heads as you cruise along cancun's scenic coastline in a captivating vehicle. our <span className="fw-700">seamless booking process</span> and <span className="fw-700">exceptional customer service</span> ensure a stress-free experience, allowing you to focus on enjoying the best of cancun car rentals. whether you're celebrating a wedding, attending a special event, or simply seeking an indulgence, monaco experiences offers the perfect blend of performance and sophistication with our luxury car rentals in cancun, mexico.
                            </p>

                            <p class="font-montserrat fw-400 fs-18 mt-10" style={{ color: '#fff' }}>
                                make your cancun memories unforgettable. choose monaco experiences for your exotic car rental needs and explore the city in unparalleled <span className="fw-700">style</span> and <span className="fw-700">luxury</span>. discover the magic of cancun behind the wheel of one of our high-end vehicles.
                            </p>
                        </div>
                    </div>
                </div>

                <LogoScrolling />

                <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                    <div class="container section-heading text-center">
                        <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                            available exotic cars in cancun
                        </h2>
                        <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                            classy and luxury rental cars in <span style={{ fontWeight: 700 }}>cancun</span> : discover our unique <span style={{ fontWeight: 700 }}>showroom experience.</span>
                        </p>
                    </div>
                    <ExoticRental16Car extraText={"RENTAL IN CANCUN"} />
                </div>


                <DriveTheDream extraText={"in cancun"} />

                <StoriesFromCustomer />
                <div style={{ background: '#111' }}>
                    <div style={{ background: '#111' }}>
                        <div className="container pt-30 pb-30">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                    frequently asked questions
                                </h2>
                                <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                    these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                                </p>
                            </div>

                            {faqItems.map((item) => (
                                <div key={item.id} className="container mt-40">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3
                                            className="font-montserrat fs-20 fw-500"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer' }}
                                        >
                                            {item.question}
                                        </h3>
                                        <h3
                                            className="font-montserrat"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                        >
                                            {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                        </h3>
                                    </div>
                                    <hr style={{ borderColor: '#BABABA' }} />
                                    {!item.isCollapsed && (
                                        <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
