import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car/ExoticRental16Car";


export default function ExoticCarRentalForPhotoshoot() {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent an exotic car for a photoshoot?",
            answer: "the cost to rent an exotic car for a photoshoot varies depending on the model, duration, and specific requirements of your shoot. please contact monaco experiences for a customized quote tailored to your needs.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "what types of luxury car rentals available for a photoshoot?",
            answer: "transform your photoshoot with our luxury car rentals, featuring everything from timeless vintage classics to the latest exotic models. each vehicle adds a touch of style and elegance to your shoot.explore our full collection here.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "can i extend my rental period if the shoot takes longer than expected?",
            answer: "yes, you can extend your rental period if the shoot takes longer than expected, subject to vehicle availability and additional charges.",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    High end car hire for Photoshoot NJ,NY,LA | Monaco Experiences
                </title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-for-photoshoot" />
            </Helmet>

            <div class="font-montserrat mt-100">
                <div
                    className="section-container"
                    style={{
                        backgroundImage: `url(../../../../monaco_assets/exotic-car-rental-photoshoot.svg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '85vh',
                        position: 'relative',
                    }}
                >
                    <div
                        className="section-content text-center"
                        style={{
                            position: 'absolute',
                            top: '55%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffffff',
                            display: 'flex',
                            minWidth: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>exotic car rental for photoshoot</h1>
                        <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                        <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                    </div>
                </div>

            </div>


            <div class="container mt-30">

                <div class="row" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12 mb-10">
                        <h2 class="font-montserrat fs-30 fw-600 mb-20" style={{ color: '#fff', textAlign: 'center', lineHeight: '1' }}>
                            picture perfect - exotic car rentals for your photoshoot
                        </h2>
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../../monaco_assets/exotic-photoshoot-1.png" alt="Image" style={{ borderRadius: 10 }} />
                    </div>
                    <div class="col-lg-8">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            elevate your photoshoot with a <span style={{ fontWeight: 700 }}>touch of luxury</span> by renting an exotic car from monaco experiences. our prestigious fleet, featuring the lamborghini huracan, ferrari f8 spyder, rolls royce dawn, and many more provides the perfect backdrop for stunning visuals.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            whether you're a professional photographer, influencer, or seeking to enhance your personal photos, our exotic cars add unmatched <span style={{ fontWeight: 700 }}>elegance</span> and <span style={{ fontWeight: 700 }}>style</span>. imagine the vibrant lamborghini against an urban skyline or the classic porsche speedster in a historic setting.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            at monaco experiences, we prioritize capturing your perfect shot. our <span style={{ fontWeight: 700 }}>seamless rental process</span>, flexible booking options, and dedicated customer service ensure a <span style={{ fontWeight: 700 }}>smooth experience</span> from start to finish. with locations across the us, accessing our luxury vehicles is convenient wherever your shoot is.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            create unforgettable images with our exotic car rentals. let monaco experiences turn your photoshoot into a showcase of <span style={{ fontWeight: 700 }}>luxury and class.</span>
                        </p>
                    </div>
                    <div class="col-lg-12 mt-20">

                        <img src="../../../../monaco_assets/exotic-photoshoot-2.png" alt="Image" style={{ borderRadius: 10 }} />

                    </div>

                    <div class="col-lg-12 mt-20">
                        <h2 class="font-montserrat fs-30" style={{ color: '#fff', textAlign: 'center' }}>
                            elevate your modeling portfolio with luxury car rentals for photoshoots
                        </h2>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            make your modeling portfolio stand out with the elegance of exotic cars from monaco experiences. our luxury vehicles, including the lamborghini huracan, ferrari f8 spyder, and rolls royce dawn, provide the <span style={{ fontWeight: 700 }}>perfect backdrop</span> to highlight your style and poise. whether shooting for high fashion, editorial spreads, or <span style={{ fontWeight: 700 }}>personal branding</span>, our exotic cars add unmatched <span style={{ fontWeight: 700 }}>glamour to your photos.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            imagine posing beside a sleek lamborghini or inside a classic rolls royce; these stunning vehicles elevate your professional image. with locations across the us, accessing our exquisite fleet is convenient wherever your shoot takes you.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            at monaco experiences, we understand the nuances of modeling photography. our seamless rental process and dedicated customer service ensure your focus remains on capturing <span style={{ fontWeight: 700 }}>the perfect shot</span>. enhance your portfolio with the unparalleled allure of monaco experiences' luxury car rentals for photoshoots.
                        </p>
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/exotic-photoshoot-3.png" alt="Image" style={{ borderRadius: 10 }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/exotic-photoshoot-4.png" alt="Image" style={{ borderRadius: 10 }} />
                    </div>

                    <div class="col-lg-4 mt-30" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="../../../../monaco_assets/exotic-photoshoot-5.png" alt="Image" style={{ borderRadius: 10 }} />
                    </div>

                </div>
            </div>


            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        available exotic car rental
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car extraText={`RENTAL FOR PHOTOSHOOT`} />
            </div>


            <DriveTheDream />

            <Contact />

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
