import React, { useEffect, useState, useContext, } from "react";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import ExoticExperiencesNationwide from "components/home/exoticExperiencesNationwide";
import ExoticRentalCar from "components/home/exoticRentalCar";
import AskQuestion from "components/home/askQuestion";
import DriveTheDream from "components/home/driveTheDream/DriveTheDream";
import ExoticRental16Car from "components/home/exoticRental16Car";
import LogoScrolling from "components/home/whyChooseUs/LogoScrolling";
import PhotoScrolling1 from "components/mansions/PhotoScrolling1";
import ContactHouse from "components/home/contact/ContactHouse";

export default function Medellin(props) {
    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "what types of vehicles do you offer for rent in medellín?",
            answer: "at house of monaco, we offer an exclusive chevrolet traverse for rent. this spacious and comfortable suv is perfect for families and groups, providing a smooth and luxurious driving experience while exploring medellín.",
            isCollapsed: true,
        },
        {
            id: 1,
            question: "is early check-in available for the luxury mansion rental in medellin?",
            answer: "yes, we offer early check-in options for our luxury mansion rentals in medellin, subject to availability. please contact us in advance to arrange and confirm your preferred check-in time.",
            isCollapsed: true,
        },
        {
            id: 3,
            question: "how many people can the house of monaco accommodate?",
            answer: "the house of monaco can comfortably accommodate up to 10 guests. with multiple bedrooms, ample living space, and luxurious bathrooms, it is perfect for any gatherings",
            isCollapsed: true,
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    SUV car rentals in Medellin | Monaco Transportation
                </title>
                <meta name="description" content="Discover the city of eternal spring in style with Monaco Transportation. We offer the best car rentals in Medellin. Explore the vibrant city and its surroundings with our reliable and comfortable SUV. Your adventure starts here!" />
                <meta name="keywords" content="" />
                <link rel="canonical" href="https://monacoexperiences.com/exotic-car-rental-medellin" />
            </Helmet>

            <div>
                <div class="font-montserrat mt-100">
                    <div
                        className="section-container"
                        style={{
                            backgroundImage: `url(../../../../monaco_assets/exotic-car-rental-medellin.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '85vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="section-content text-center"
                            style={{
                                position: 'absolute',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#ffffff',
                                display: 'flex',
                                minWidth: '100%',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <h1 className="font-montserrat fs-60 fw-700" style={{ color: '#fff' }}>car rental medellín, columbia</h1>
                            <h2 className="font-montserrat fs-31 fw-600" style={{ color: '#fff' }}>making your dreams a reality</h2>
                            <img className="mt-10" src="../../../../monaco_assets/logo.png" style={{ height: '100px' }} />
                        </div>
                    </div>
                </div>


                <div class="container mb-30" >
                    <div class="row align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">
                        <div class="col-lg-12 mt-30 mb-30">
                            <h2 class="font-montserrat fs-40 fw-700" style={{ color: '#fff', textAlign: 'center' }}>
                                navigate medellín like a local : top car rental services
                            </h2>
                        </div>

                        <div class="col-lg-6" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src="../../../../monaco_assets/houseofmonaco-6.png" alt="Image" style={{ maxHeight: '460px' }} />
                        </div>
                        <div class="col-lg-6">
                            <p class="font-montserrat fw-300 fs-18" style={{ color: '#fff' }}>
                                discover the <span className="fw-700">vibrant city of medellín</span> with ease and comfort by choosing monaco transportation's top car rental services. our chevrolet traverse is perfect for exploring the city's bustling streets and <span className="fw-700">scenic landscapes</span>.
                            </p>
                            <p class="font-montserrat fw-300 fs-18 mt-20" style={{ color: '#fff' }}>
                                our car rental in medellin, colombia, offers the freedom and flexibility to navigate the city like a local. from the lively <span className="fw-700">el poblado</span> district to the tranquil hills of santa elena, every corner of the city is <span className="fw-700">within your reach</span>. with our expert local knowledge, we can recommend the best routes and must-see attractions.
                            </p>
                            <p class="font-montserrat fw-300 fs-18 mt-20" style={{ color: '#fff' }}>
                                our car rental medellin services cater to your needs with <span className="fw-700">flexible rental terms</span> and a seamless booking process. simply choose our services and we'll handle the rest. our <span className="fw-700">commitment to customer</span> satisfaction ensures a smooth and <span className="fw-700">enjoyable experience.</span>
                            </p>
                            <p class="font-montserrat fw-300 fs-18 mt-20" style={{ color: '#fff' }}>
                                experience <span className="fw-700">medellín in style</span> with monaco transportation's car rental  medellin services. book now and make your <span className="fw-700">journey unforgettable.</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-30">
                        <img src="../../../../monaco_assets/medellin-1.png" alt="Image" />
                    </div>

                    <div class="col-lg-12 mt-20">
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            discover the <span className="fw-700">vibrant city of medellín</span> with ease and comfort by choosing monaco transportation's top car rental services. our chevrolet traverse is perfect for exploring the city's bustling streets and <span className="fw-700">scenic landscapes.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our car rental in medellin, colombia, offers the freedom and flexibility to navigate the city like a local. from the lively <span className="fw-700">el poblado</span> district to the tranquil hills of santa elena, every corner of the city is <span className="fw-700">within your reach</span>. with our expert local knowledge, we can recommend the best routes and must-see attractions.
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            our car rental medellin services cater to your needs with <span className="fw-700">flexible rental terms</span> and a seamless booking process. simply choose our services and we'll handle the rest. our <span className="fw-700">commitment to customer</span> satisfaction ensures a smooth and <span className="fw-700">enjoyable experience.</span>
                        </p>
                        <p class="font-montserrat fw-300 fs-20 mt-10" style={{ color: '#fff' }}>
                            experience <span className="fw-700">medellín in style</span> with monaco transportation's car rental  medellin services. book now and make your <span className="fw-700">journey unforgettable.</span>
                        </p>

                    </div>
                </div>


                <PhotoScrolling1 />

                <div class='col-lg-12 mt-20'>
                    <div class="section-heading text-center " data-aos="fade-up" data-aos-duration="1200">
                        <div class="d-flex mb-10" style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <a target="_blank" href="https://www.instagram.com/monacotransportation/" style={{ color: '#fff', marginRight: '5px' }}>
                                <span style={{ display: 'inline' }}>
                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                </span>
                                <span class="font-montserrat fs-20 fw-600" style={{ color: '#ffffff', display: 'inline' }}>
                                    &nbsp;visit- @monacotransportation
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <ContactHouse />


                <div style={{ background: '#111' }}>
                    <div style={{ background: '#111' }}>
                        <div className="container pt-30 pb-30">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                    frequently asked questions
                                </h2>
                                <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                    these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                                </p>
                            </div>

                            {faqItems.map((item) => (
                                <div key={item.id} className="container mt-40">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <h3
                                            className="font-montserrat fs-20 fw-500"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer' }}
                                        >
                                            {item.question}
                                        </h3>
                                        <h3
                                            className="font-montserrat"
                                            onClick={() => toggleCollapse(item.id)}
                                            style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                        >
                                            {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                        </h3>
                                    </div>
                                    <hr style={{ borderColor: '#BABABA' }} />
                                    {!item.isCollapsed && (
                                        <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
